export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "LIST_CATALOG_SUCCESS":
    case "LIST_CATALOG_ERROR":
      return {
        ...state,
        CatalogResponse: action.updatePayload,
      };
    case "LIST_CATALOG_HISTORY_SUCCESS":
    case "LIST_CATALOG_HISTORY_ERROR":
      return {
        ...state,
        ListCatalogHistoryResponse: action.updatePayload,
      };
    case "CATALOG_CONFIG_SUCCESS":
    case "CATALOG_CONFIG_ERROR":
      return {
        ...state,
        GetCatalogConfigResponse: action.updatePayload,
      };
    case "UPDATE_CATALOG_CONFIG_SUCCESS":
    case "UPDATE_CATALOG_CONFIG_ERROR":
      return {
        ...state,
        UpdateCatalogConfigResponse: action.updatePayload,
      };
    case "CATALOG_MANUAL_MAPPING_SUCCESS":
    case "CATALOG_MANUAL_MAPPING_ERROR":
      return {
        ...state,
        CatalogManualMappingResponse: action.updatePayload,
      };
    case "LIST_PRODUCT_TYPE_SUCCESS":
    case "LIST_PRODUCT_TYPE_ERROR":
      return {
        ...state,
        ListProductTypeResponse: action.updatePayload,
      };
    case "DELETE_CATALOG_MANUAL_SUCCESS":
    case "DELETE_CATALOG_MANUAL_ERROR":
      return {
        ...state,
        DeleteCatalogManualResponse: action.updatePayload,
      };
    case "DELETE_FEED_PRODUCT_SUCCESS":
    case "DELETE_FEED_PRODUCT_ERROR":
      return {
        ...state,
        DeleteFeedProductResponse: action.updatePayload,
      };
    case "ITEMS_OFFER_GET_RESELLER_LIST_SUCCESS":
    case "ITEMS_OFFER_GET_RESELLER_LIST_ERROR":
      return {
        ...state,
        ItemsOfferGetResellerListResponse: action.updatePayload,
      };
    case "ITEMS_OFFER_GET_RESELLER_PRODUCT_LIST_SUCCESS":
    case "ITEMS_OFFER_GET_RESELLER_PRODUCT_LIST_ERROR":
      return {
        ...state,
        ItemsOfferGetResellerProductListResponse: action.updatePayload,
      };
    case "GET_LIST_UPLOADED_DATA_SUCCESS":
    case "GET_LIST_UPLOADED_DATA_ERROR":
      return {
        ...state,
        GetListUploadedDataResponse: action.updatePayload,
      };
    case "FEED_PRODUCT_UPLOAD_SUCCESS":
    case "FEED_PRODUCT_UPLOAD_ERROR":
      return {
        ...state,
        FeedProductUploadResponse: action.updatePayload,
      };
    case "AMAZON_LQS_SUCCESS":
    case "AMAZON_LQS_ERROR":
      return {
        ...state,
        AmazonLQSListResponse: action.updatePayload,
      };
    case "CREATE_AMAZON_LQS_SUCCESS":
    case "CREATE_AMAZON_LQS_ERROR":
      return {
        ...state,
        CreateAmazonLQSResponse: action.updatePayload,
      };
    case "ITEMS_OFFER_TABLE_DATA_SUCCESS":
    case "ITEMS_OFFER_TABLE_DATA_ERROR":
      return {
        ...state,
        ItemsOfferTableDataResponse: action.updatePayload,
      };
    case "GET_CATELOG_OVERVIEW_SUCCESS":
    case "GET_CATELOG_OVERVIEW_ERROR":
      return {
        ...state,
        GetCatelogOverviewResponse: action.updatePayload,
      };
    case "SALES_CHANNEL_LIST_SUCCESS":
    case "SALES_CHANNEL_LIST_ERROR":
      return {
        ...state,
        SalesChannelListResponse: {
          ...action.updatePayload,
          data: {
            records: action.updatePayload?.data?.map((d, i) => ({
              default_marketplace: d?.default_marketplace,
              id: i + 1,
              sales_channel: d?.sales_channel,
              account_type: d?.account_type,
              marketplace: d?.name,
              marketplace_id: `${d?.id}_${i}`,
              marketplace_flag: d?.id,
              region: d?.region_name,
              region_label: d?.account_type || "Seller",
              short_name: d?.short_name,
            })),
          },
        },
      };
    case "GET_MARKETPLACE_LIST_SUCCESS":
    case "GET_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: action.updatePayload,
      };
    case "GET_CHART_DATA_SUCCESS":
    case "GET_CHART_DATA_ERROR":
      return {
        ...state,
        GetChartDataResponse: action.updatePayload,
      };
    case "ITEMS_OFFER_GET_SELLER_LIST_SUCCESS":
    case "ITEMS_OFFER_GET_SELLER_LIST_ERROR":
      return {
        ...state,
        ItemsOfferGetSellerListResponse: action.updatePayload,
      };
    case "ITEMS_OFFER_GET_ASIN_OFFERS_SUCCESS":
    case "ITEMS_OFFER_GET_ASIN_OFFERS_ERROR":
      return {
        ...state,
        ItemsOfferGetASINOffersResponse: action.updatePayload,
      };
    case "ITEMS_OFFER_GET_ASIN_OFFERS_GRAPH_SUCCESS":
    case "ITEMS_OFFER_GET_ASIN_OFFERS_GRAPH_ERROR":
      return {
        ...state,
        ItemsOfferGetASINOffersGraphResponse: action.updatePayload,
      };
    case "ITEMS_OFFER_GET_SELLER_OFFERS_GRAPH_SUCCESS":
    case "ITEMS_OFFER_GET_SELLER_OFFERS_GRAPH_ERROR":
      return {
        ...state,
        ItemsOfferGetSellerOffersGraphResponse: action.updatePayload,
      };
    case "ITEMS_OFFER_TABLE_DATA_EXPORT_SUCCESS":
    case "ITEMS_OFFER_TABLE_DATA_EXPORT_ERROR":
      return {
        ...state,
        ItemsOfferTableDataExportResponse: action.updatePayload,
      };
    case "UPDATE_CATALOG_MANUAL_SUCCESS":
    case "UPDATE_CATALOG_MANUAL_ERROR":
      return {
        ...state,
        UpdateCatalogManualResponse: action.updatePayload,
      };
    case "CREATE_FEED_LISTING_LOGS_SUCCESS":
    case "CREATE_FEED_LISTING_LOGS_ERROR":
      return {
        ...state,
        CreateFeedListingLogsResponse: action.updatePayload,
      };

    case "FAKE_ACTION_CATALOG":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
