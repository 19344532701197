import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BulkCatalogUpload from "../../modules/pages/catalogue/bulk-catalog-upload";
import {
  SalesChannelListAction,
  ListProductTypeMarketplaceAction,
  GetListUploadedDataAction,
  DeleteFeedProduct,
  FeedProductUploadAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  ListProductTypeResponse: state.Catalog.ListProductTypeResponse,
  GetListUploadedDataResponse: state.Catalog.GetListUploadedDataResponse,
  FeedProductUploadResponse: state.Catalog.FeedProductUploadResponse,
  DeleteFeedProductResponse: state.Catalog.DeleteFeedProductResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SalesChannelListAction,
      ListProductTypeMarketplaceAction,
      GetListUploadedDataAction,
      FeedProductUploadAction,
      DeleteFeedProduct,
      fakeActionCatalog,
    },
    dispatch
  );

const BulkCatalogUpload_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkCatalogUpload);

export default BulkCatalogUpload_;
