import axiosCall from "../../configurations/network-services/axiosCall";

export const GetCatalogListAmazonAction = (data) => {
  const path = `list-catalog-amazon?pageSize=${data?.perPage || ""}&page=${
    data?.page || ""
  }&status=${data?.status || ""}&category=${
    data?.category || ""
  }&sub_category=${data?.sub_category || ""}&product_name=${
    data?.product_name || ""
  }&parent_sku=${data?.parent_sku || ""}&brand=${
    data?.brand || ""
  }&color_name=${data?.color_name || ""}&sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&parent_asin=${data?.parent_asin || ""}`;
  const responseType = "LIST_CATALOG_AMAZON_TABLE";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCatalogListAction = (data) => {
  const path = `check-asin-catalog?sales_channel=${
    data?.sales_channel || ""
  }&filter_value=${data?.asin || ""}`;
  const responseType = "LIST_CATALOG_AMAZON";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCataLogCountry = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CATALOG", state: data });
};
