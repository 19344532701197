import AccountStatus_ from "../../containers/account-status";
import ManageCompany_ from "../../containers/admin/company";
import ManageAdmin_ from "../../containers/admin/super-admin";
import BrandRevenueListASINWiser from "../../containers/agency";
import CentralLog_ from "../../containers/application-logs/cls";
import SystemLog__ from "../../containers/application-logs/system";
import Catalog_ from "../../containers/catalog";
import CatalogueByCountry_ from "../../containers/catalogue-by-country";
import ManageUser_ from "../../containers/manage-user";
import ProfitabilityCalculator_ from "../../containers/profitability-calculator";
import ReportsASINWiser from "../../containers/reports";
import SalesAnalysis__ from "../../containers/sales-analytics";
import Overview__ from "../../containers/sales-analytics/overview";
import ProductDetails__ from "../../containers/sales-analytics/product-details";
import UserSetting__ from "../../containers/user-setting";
import ClientData from "../../modules/pages/client-data";

import AdminSetting_ from "../../containers/admin/admin-setting";
import Setting from "../../modules/pages/user-setting";

import OverView_ from "../../containers/catalog/over-view";
import Tools_ from "../../containers/catalog/tools";
import VendorOrder____ from "../../containers/vendor-order";
import VendorOrder__ from "../../containers/vendor-overview";

import InventoryDetailsASIN from "../../containers/Inventory/details";
import BrandRevenueList from "../../containers/brand-revenue-list";
import CompetitorResearch from "../../containers/competitor-research";
import ProductResearch from "../../containers/product-research";
import AmazonLqs_ from "../../containers/catalog/AmazonLqs";
import Criteria from "../../modules/pages/criteria";
import Criteria_ from "../../containers/criteria";
import CampaignManagement_ from "../../containers/campaign-management";
import TestPage__ from "../../containers/sales-analytics/report-account";
import AdvertisingAccount_ from "../../containers/sales-analytics/advertising-account";
import EmbedCodes_ from "../../containers/admin/embed-codes";
import BulkCatalogUpload_ from "../../containers/catalog/bulk-catalog-upload";
import BrandMonitor_ from "../../containers/brand-protection/brand-monitor";
import ResellerMonitor_ from "../../containers/brand-protection/reseller-monitor";

export const userRoutersList = [
  {
    path: "/analytics/performance",
    key: "analytics",
    name: SalesAnalysis__,
  },
  {
    path: "/vendor-order",
    key: "analytics",
    name: VendorOrder____,
  },
  {
    path: "/analytics/vendor-performance",
    key: "analytics",
    name: VendorOrder__,
  },
  {
    path: "/analytics/product-details",
    key: "analytics",
    name: ProductDetails__,
  },
  {
    path: "/analytics/overview",
    key: "analytics",
    name: Overview__,
  },
  {
    path: "/analytics/report-account",
    key: "analytics",
    name: TestPage__,
  },
  {
    path: "/analytics/advertising-account",
    key: "analytics",
    name: AdvertisingAccount_,
  },
  {
    path: "/",
    key: "analytics",
    name: Overview__,
  },

  {
    path: "/finance", //1
    key: "inventory",
    name: ProfitabilityCalculator_,
  },
  {
    path: "/brand-protection/reseller-monitor", //1
    key: "brand-protection",
    name: ResellerMonitor_,
  },
  {
    path: "/brand-protection/brand-monitor", //1
    key: "brand-protection",
    name: BrandMonitor_,
  },
  {
    path: "/catalog/over-view", //1
    key: "catalog",
    name: OverView_,
  },
  {
    path: "/campaign-management", //1
    key: "campaign-management",
    name: CampaignManagement_,
  },
  {
    path: "/catalog/catalog", //1
    key: "catalog",
    name: Catalog_,
  },
  {
    path: "/catalog/amazon-quality-score", //1
    key: "catalog",
    name: AmazonLqs_,
  },
  {
    path: "/catalog/amazon-catalog-health", //1
    key: "catalog",
    name: CatalogueByCountry_,
  },
  {
    path: "/catalog/bulk-catalog-upload", //1
    key: "catalog",
    name: BulkCatalogUpload_,
  },
  {
    path: "/brand-protection/amazon-buybox", //1
    key: "catalog",
    name: Tools_,
  },

  {
    path: "/account-status", ///11
    key: "account-status",
    name: AccountStatus_,
  },
  {
    path: "/inventory", //1
    key: "inventory",
    name: InventoryDetailsASIN,
  },

  {
    path: "/reports", //1
    key: "reports",
    name: ReportsASINWiser,
  },
  // {
  //   path: "/advertising-analytics", //1
  //   key: "advertising-analytics",
  //   name: AdvertisingAnalytics,
  // },

  {
    path: "/competitor-research", //1
    key: "competitor-research",
    name: CompetitorResearch,
  },

  {
    path: "/analytics", // 111
    key: "analytics",
    name: SalesAnalysis__,
  },

  {
    path: "/product-research", //1
    key: "product-research",
    name: ProductResearch,
  },

  {
    path: "/amazon-catalogue-health", //1
    key: "amazon-catalogue-health",
    name: CatalogueByCountry_,
  },

  {
    path: "/brand-analytics", //1
    key: "brand-analytics",
    name: BrandRevenueList,
  },
  {
    path: "/client-data", //1
    key: "client-data",
    name: ClientData,
  },

  {
    path: "/agency", //1
    key: "brand-analytics",
    name: BrandRevenueListASINWiser,
  },
  {
    path: "/setting", //1
    key: "setting",
    name: Setting,
  },
  {
    path: "/callbackadsapi", //1
    key: "setting",
    name: UserSetting__,
  },
  {
    path: "/callback_sp", //1
    key: "setting",
    name: UserSetting__,
  },
  {
    path: "/application-logs/central-log", //1
    key: "application-logs",
    name: CentralLog_,
  },
  {
    path: "/application-logs/system-log", //1
    key: "application-logs",
    name: SystemLog__,
  },
];

export const adminRoutersList = [
  {
    path: "/", //1
    key: "manage-user",
    name: ManageUser_,
  },

  {
    path: "/manage-user", //1
    key: "manage-user",
    name: ManageUser_,
  },
  {
    path: "/criteria", //1
    key: "manage-user",
    name: Criteria_,
  },
  {
    path: "/embed-codes", //1
    key: "manage-user",
    name: EmbedCodes_,
  },
  {
    path: "/setting", //1
    key: "setting",
    name: AdminSetting_,
  },
  {
    path: "/manage-admin", //1
    key: "manage-admin",
    name: ManageAdmin_,
  },
  {
    path: "/manage-agency", //1
    key: "manage-agency",
    name: ManageCompany_,
  },
];
