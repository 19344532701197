import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import PageLoader from "../components/page-loader";
import PageRouter from "../routes";
import NewUser from "./new-user";
import Sidebar_ from "../containers/sidebar";
import { GlobalContext } from "../commonContext";
import dayjs from "dayjs";

const HeaderASINWiser = React.lazy(() => import("../containers/layout/header"));

const Footer = React.lazy(() => import("./layouts/footer"));

export default function (props) {
  const location = useLocation();

  const [hide, setHide] = useState(false);
  const [moveToLogin, setMoveToLogin] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [hideMenus, setHideMenus] = useState(false);

  const [amazonIdIs, setAmazonIdIs] = useState(false);
  const contextValue = useContext(GlobalContext);

  const [userData, setUserData] = useState(
    localStorage.getItem("user") == "undefined"
      ? {}
      : JSON.parse(localStorage.getItem("user"))
  );
  window.updateProfile = (e) => {
    setUserData(e);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) return;
    try {
      if (JSON.parse(localStorage.getItem("user") || {})?.u_type === 1) {
        setAmazonIdIs(false);
        return;
      }
      if (
        JSON.parse(localStorage.getItem("user") || {})?.credentials === 0 &&
        !(
          location?.pathname === "/setting" ||
          location?.pathname === "/callback_sp" ||
          location?.pathname === "/callbackadsapi"
        )
      ) {
        setAmazonIdIs(true);
      }
    } catch (error) {}
  }, [
    location?.pathname,
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user") || {})?.credentials
      : "",
  ]);
  console.log(amazonIdIs, "amazonIdIs");

  const expandSidebar = () => {
    if (991 >= window.innerWidth) {
      const element = document.getElementById("kt_aside");
      const commonFix = "aside pt-7 pb-4 pb-lg-7 pt-lg-10 drawer drawer-start";
      if (!element) return;
      element.classList = hide
        ? `${commonFix} drawer drawer-start drawer-on`
        : commonFix;
    }

    if (hide) {
      const node = document.createElement("div");

      node.onclick = () => setHide(false);
      node.style.zIndex = 109;
      document.getElementById("root").appendChild(node);
    } else {
      const node = document.getElementById("drawer-overlay");
      if (node !== null) {
        node.remove();
      }
    }
  };

  const checkWidth = () => {
    setHideMenus(690 > window.innerWidth);
    const element = document.getElementById("kt_aside");
    const commonFix = "aside pt-7 pb-4 pb-lg-7 pt-lg-10";
    if (!element) return;
    element.classList =
      991 >= window.innerWidth ? `${commonFix} drawer drawer-start` : commonFix;
    element.style.width = 991 >= window.innerWidth ? "250px" : "300px";
    const node = document.getElementById("drawer-overlay");
    if (node !== null) {
      node.remove();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      checkWidth();
    });
    try {
      const date_range = {
        start_date: dayjs().subtract(30, "days")?.format("YYYY-MM-DD"),
        end_date: dayjs()?.format("YYYY-MM-DD"),
      };
      if (!localStorage.getItem("date_range")) {
        localStorage.setItem("date_range", JSON.stringify(date_range));
        contextValue?.updateCommonGlobalVal({
          date_range,
        });
      } else {
        contextValue?.updateCommonGlobalVal({
          date_range: JSON.parse(localStorage.getItem("date_range")),
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    expandSidebar();
  }, [hide]);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 992) {
        setCollapsed(true);
        setHide(true);
      } else {
        setCollapsed(false);
        setHide(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  if (
    !localStorage.getItem("user") ||
    !localStorage.getItem("token") ||
    localStorage.getItem("user") === "undefined"
  ) {
    localStorage.clear();
    return <Redirect to={"/login"} />;
  }
  if (!localStorage.getItem("token") || moveToLogin) {
    localStorage.clear();
    return window.location.assign("/login");
  }

  const GetModules = () =>
    localStorage.getItem("userType") == 2 ? false : true;
  const UserProfile = contextValue?.data?.user_;

  return (
    <>
      <div
        className="fade-in d-flex flex-column flex-root"
        style={{ height: "100%" }}
      >
        <div
          className="page d-flex flex-row flex-column-fluid"
          style={{ height: "100%" }}
        >
          <div
            className="fadeInLeft"
            style={{
              zIndex: window.innerWidth >= 992 ? "999" : "1000",
            }}
          >
            <Sidebar_
              logout={() => setMoveToLogin(true)}
              user={userData || {}}
              hideMenus={hideMenus}
              collapsed={collapsed}
              userType={GetModules()}
              UserProfile={UserProfile}
              setHideMenus={setHideMenus}
              setCollapsed={() => setCollapsed(!collapsed)}
              bootStatus={false}
              {...props}
            />
          </div>
          <div className=" d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <HeaderASINWiser
              backToAdmin={() => {}}
              logout={() => setMoveToLogin(true)}
              backToUser={setUserData}
              hideMenus={hideMenus}
              userData={userData}
              setHideMenus={setHideMenus}
              UserProfile={UserProfile}
              setCollapsed={() => setCollapsed(!collapsed)}
              collapsed={collapsed}
              {...props}
            />
            <div className=" d-flex flex-column flex-row-fluid mt-5 mx-5">
              <div className=" flex-column flex-column-fluid ">
                <PageRouter authStatus={GetModules()} {...props} />
              </div>
              <Footer {...props} />
            </div>
          </div>
        </div>
      </div>
      <NewUser
        show={amazonIdIs}
        close={() => {
          setAmazonIdIs("close");
        }}
      />
    </>
  );
}
