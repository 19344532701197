import axiosCall from "../../configurations/network-services/axiosCall";

export const GetListCatalogAction = (data) => {
  const path = `list-catalog-manual?category=${
    data?.category || ""
  }&sub_category=${data?.subCategory || ""}&product_name=${
    data?.product_name || ""
  }&child_sku=${data?.sku || ""}&child_asin=${data?.asin || ""}&ean=${
    data?.ean || ""
  }&pageSize=${data?.perPage || ""}&page=${data?.page || ""}&sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}`;
  const responseType = "LIST_CATALOG";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetListAmazonLQSAction = (data) => {
  const path = `list-amazon-lqs?pageSize=${data?.perPage || ""}&account_type=${
    data?.account_type || ""
  }&sort=${data?.sort || ""}&category=${data?.category || ""}&sub_category=${
    data?.subCategory || ""
  }&filter_value=${data?.filter_value || ""}&page=${
    data?.page || ""
  }&sales_channel=${data?.sales_channel || ""}&region=${
    data?.region || ""
  }&lqa_filter=${data?.lqa_filter || ""}&export=${data?.export || ""}`;
  const responseType = "AMAZON_LQS";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetListAmazonLQSHistoryAction = (data) => {
  const path = `list-amazon-lqs-history?pageSize=${
    data?.perPage || ""
  }&account_type=${data?.account_type || ""}&page=${
    data?.page || ""
  }&sales_channel=${data?.sales_channel || ""}&region=${data?.region || ""}`;
  const responseType = "LIST_CATALOG_HISTORY";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetListUploadedDataAction = (data) => {
  const path = `list-feed-product-upload?pageSize=${
    data?.perPage || ""
  }&filter_value=${data?.filter_value || ""}&page=${
    data?.page || ""
  }&product_type=${data?.product_type || ""}`;
  const responseType = "GET_LIST_UPLOADED_DATA";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const FeedProductUploadAction = (data) => {
  let FormData_ = new FormData();
  FormData_.append("region", data?.region);
  FormData_.append("sales_channel", data?.sales_channel);
  FormData_.append("account_type", data?.account_type);
  FormData_.append("product_type", data?.product_type);
  FormData_.append(
    "UploadedFeedDataFile[importFile]",
    data?.["UploadedFeedDataFile[importFile]"]
  );

  const path = `feed-product-upload`;
  const responseType = "FEED_PRODUCT_UPLOAD";

  return axiosCall("post", path, responseType, FormData_, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateFeedListingLogs = (data) => {
  const path = `create-feed-listing-logs`;
  const responseType = "CREATE_FEED_LISTING_LOGS";

  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const DeleteFeedProduct = (id) => {
  const path = `delete-feed-product/${id}`;
  const responseType = "DELETE_FEED_PRODUCT";

  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetListCatalogConfigAction = (data) => {
  const path = `get-module-config?module=catalog_manual`;
  const responseType = "CATALOG_CONFIG";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ListProductTypeMarketplaceAction = (data) => {
  const path = `list-product-type?marketplace_id=${data?.id}`;
  const responseType = "LIST_PRODUCT_TYPE";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const DeleteCatalogManualAction = (id) => {
  const path = `delete-catalog-manual/${id}`;
  const responseType = "DELETE_CATALOG_MANUAL";

  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CreateAmazonLQSAction = (data) => {
  const path = `create-amazon-lqs`;
  const responseType = "CREATE_AMAZON_LQS";

  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetCatalogOverviewAction = (data) => {
  const path = `get-catalog-overview`;
  // ?region=${
  //   data?.region || ""
  // }&sales_channel=${data?.sales_channel || ""}
  const responseType = "GET_CATELOG_OVERVIEW";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const UpdateCatalogConfigAction = (data) => {
  const path = `update-module-config`;
  const responseType = "UPDATE_CATALOG_CONFIG";

  return axiosCall(
    "POST",
    path,
    responseType,
    {
      module: "catalog_manual",
      config: data,
    },
    {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  );
};

export const ChartAPIDataAction = (data) => {
  const path = `items-offer/chart-data?sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&asin=${data?.asin || ""}&is_buybox_winner=${
    data?.is_buybox_winner || ""
  }&is_own_winner=${data?.is_own_winner || ""}&account_type=${
    data?.account_type || ""
  }&status=${data?.status || ""}`;
  const responseType = "GET_CHART_DATA";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ItemsOfferTableDataAction = (data) => {
  const path = `items-offer/table-data?sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&asin=${data?.asin || ""}&pageSize=${
    data?.perPage || 20
  }&page=${data?.page || 1}&is_buybox_winner=${
    data?.is_buybox_winner || ""
  }&is_own_winner=${data?.is_own_winner || ""}&account_type=${
    data?.account_type || ""
  }&sort=${data?.sort || ""}&status=${data?.status || ""}`;
  const responseType = "ITEMS_OFFER_TABLE_DATA";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ItemsOfferTableDataExportAction = (data) => {
  const path = `items-offer/table-data?sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&export=all&is_buybox_winner=${
    data?.is_buybox_winner === "11"
      ? "0"
      : data?.is_buybox_winner === "00"
      ? 1
      : data?.is_buybox_winner === "0"
      ? "0"
      : data?.is_buybox_winner === 1
      ? 1
      : ""
  }&asin=${data?.asin || ""}&export=all`;
  const responseType = "ITEMS_OFFER_TABLE_DATA_EXPORT";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CatalogManualMappingAction = (data) => {
  const path = `catalog-manual-mapping`;
  const responseType = "CATALOG_MANUAL_MAPPING";

  return axiosCall("POST", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SalesChannelListAction = (data) => {
  const path = `get-user-marketplace-list`;
  const responseType = "SALES_CHANNEL_LIST";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetMarketplaceListAction = () => {
  const path = `get-marketplace-list`;
  const responseType = "GET_MARKETPLACE_LIST";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ItemsOfferGetSellerList = () => {
  const path = `items-offer/get-seller-list`;
  const responseType = "ITEMS_OFFER_GET_SELLER_LIST";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ItemsOfferGetASINOffers = (data) => {
  const path = `items-offer/get-asin-offers?sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&asin=${data?.asin || ""}&account_type=${
    data?.account_type || ""
  }`;
  const responseType = "ITEMS_OFFER_GET_ASIN_OFFERS";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ItemsOfferGetASINOffersGraph = (data) => {
  const path = `items-offer/get-asin-offers-graph?sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&asin=${data?.asin || ""}&account_type=${
    data?.account_type || ""
  }&is_buybox_winner=${data?.is_buybox_winner || ""}&is_own_winner=${
    data?.is_own_winner || ""
  }&status=${data?.status || ""}`;
  const responseType = "ITEMS_OFFER_GET_ASIN_OFFERS_GRAPH";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ItemsOfferGetSellerOffersGraph = (data) => {
  const path = `items-offer/get-seller-offers-graph?sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&asin=${data?.asin || ""}&account_type=${
    data?.account_type || ""
  }&seller_id=${data?.seller_id || ""}`;
  const responseType = "ITEMS_OFFER_GET_SELLER_OFFERS_GRAPH";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const UpdateCatalogModal = (id, data) => {
  const path = `update-catalog-manual/${id}`;
  const responseType = "UPDATE_CATALOG_MANUAL";

  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ItemsOfferGetResellerList = (data) => {
  const path = `items-offer/get-reseller-list?sales_channel=${
    data?.sales_channel || ""
  }&region=${data?.region || ""}&account_type=${data?.account_type || ""}`;
  const responseType = "ITEMS_OFFER_GET_RESELLER_LIST";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ItemsOfferGetResellerProductList = (data) => {
  const path = `items-offer/get-reseller-product-list?region=${
    data?.region || ""
  }&account_type=${data?.account_type || ""}&sales_channel=${
    data?.sales_channel || ""
  }&seller_id=${data?.seller_id || ""}`;
  const responseType = "ITEMS_OFFER_GET_RESELLER_PRODUCT_LIST";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCatalog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CATALOG", state: data });
};
