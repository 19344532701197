import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Empty,
  Input,
  Select,
  Spin,
  Table,
  Statistic,
  Tag,
  Button,
  message,
  Cascader,
  Segmented,
} from "antd";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import {
  AllMarketPlace,
  DefaultPerPage,
  FormatCurrency,
  SwapCommaAndDot,
} from "../../../config";
import Pagination from "../../../components/pagination";
import AsinView from "./lib/asin-view";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";

const Tools = (props) => {
  const {
    fakeActionCatalog,
    SalesChannelListAction,
    ItemsOfferTableDataAction,
    ChartAPIDataAction,
    ItemsOfferTableDataExportAction,
    ItemsOfferGetSellerList,
    ItemsOfferGetASINOffersGraph,
  } = props;

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const ItemsOfferTableDataResponse = useSelector(
    (state) => state.Catalog.ItemsOfferTableDataResponse || {}
  );
  const GetChartDataResponse = useSelector(
    (state) => state.Catalog.GetChartDataResponse || {}
  );

  const ItemsOfferGetSellerListResponse = useSelector(
    (state) => state.Catalog.ItemsOfferGetSellerListResponse || {}
  );
  const ItemsOfferTableDataExportResponse = useSelector(
    (state) => state.Catalog.ItemsOfferTableDataExportResponse || {}
  );
  const [listSortFilters, setListSortFilters] = useState({
    key: "",
    type: "",
  });

  const ItemsOfferGetASINOffersGraphResponse = useSelector(
    (state) => state.Catalog.ItemsOfferGetASINOffersGraphResponse || {}
  );

  const [graphData, setGraphData] = useState([]);
  const [sign, setSign] = useState("$");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [prValue, setPrValue] = useState(0);
  const [filters, setFilters] = useState({
    search_text: "",
    is_buybox_winner: "1",
    is_own_winner: null,
    status: null,
  });
  const [asinWiseGraph, setAsinWiseGraph] = useState([]);
  const [asinWiseGraphLoading, setAsinWiseGraphLoading] = useState(true);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    account_type: null,
  });
  const [editView, setEditView] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [sellerList, setSellerList] = useState([]);
  const [sellerListLoading, setSellerListLoading] = useState(true);

  const [text, setText] = useState(null);

  const onChange = (_, selectedOptions) => {
    const selectedValue = {
      root: selectedOptions?.[0],
      value: selectedOptions?.[1],
    };

    setText(selectedValue);
  };

  function downloadFile(url) {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const fileName = pathname.split("/").pop();

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    message.destroy();
  }

  useEffect(() => {
    if (ItemsOfferTableDataExportResponse?.status) {
      downloadFile(ItemsOfferTableDataExportResponse?.data?.file);
      fakeActionCatalog("ItemsOfferTableDataExportResponse");
    } else if (ItemsOfferTableDataExportResponse?.status === false) {
      message.destroy();
      message.error(ItemsOfferTableDataExportResponse?.message);
      fakeActionCatalog("ItemsOfferTableDataExportResponse");
    }
  }, [ItemsOfferTableDataExportResponse]);

  useEffect(() => {
    if (ItemsOfferGetSellerListResponse?.status) {
      setSellerList(ItemsOfferGetSellerListResponse.data?.records || []);
      setSellerListLoading(false);
      fakeActionCatalog("ItemsOfferGetSellerListResponse");
    } else if (ItemsOfferGetSellerListResponse?.status === false) {
      setSellerListLoading(false);
      setSellerList([]);
      fakeActionCatalog("ItemsOfferGetSellerListResponse");
    }
  }, [ItemsOfferGetSellerListResponse]);

  useEffect(() => {
    if (GetChartDataResponse?.status === true) {
      console.log(GetChartDataResponse, "GetChartDataResponse");
      setGraphData(GetChartDataResponse?.data || []);

      fakeActionCatalog("GetChartDataResponse");
    } else if (GetChartDataResponse?.status === false) {
      setGraphData([]);

      fakeActionCatalog("GetChartDataResponse");
    }
  }, [GetChartDataResponse]);
  useEffect(() => {
    if (ItemsOfferTableDataResponse?.status === true) {
      setList(ItemsOfferTableDataResponse?.data?.data || []);
      setTotalPage(
        ItemsOfferTableDataResponse?.data?.pagination?.totalCount || []
      );
      setSign(ItemsOfferTableDataResponse?.data?.currency || "");
      setPrValue(ItemsOfferTableDataResponse?.data?.cardData);
      setLoading(false);
      fakeActionCatalog("ItemsOfferTableDataResponse");
    } else if (ItemsOfferTableDataResponse?.status === false) {
      setList([]);
      setSign("");
      setLoading(false);
      fakeActionCatalog("ItemsOfferTableDataResponse");
    }
  }, [ItemsOfferTableDataResponse]);
  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );

            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                        {/* {`Amazon.${e?.account_type || ''}.${e?.short_name || ''}`} */}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  },
                ],
              });
              if (e?.default_marketplace === 1) {
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  marketplace_id: e.marketplace_id,
                  region: e.region,
                  sales_channel: e.sales_channel,
                  account_type: e?.account_type,
                });
                setLoading(true);
                setList([]);
                ItemsOfferTableDataAction({
                  region: e.region,
                  sales_channel: e.sales_channel,
                  asin: filters?.search_text,
                  is_buybox_winner: filters?.is_buybox_winner,
                  account_type: e?.account_type,
                });
                ChartAPIDataAction({
                  region: e.region,
                  sales_channel: e.sales_channel,
                  asin: filters?.search_text,
                  is_buybox_winner: filters?.is_buybox_winner,
                  account_type: e?.account_type,
                });
              }
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);

      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);
  useEffect(() => {
    if (ItemsOfferGetASINOffersGraphResponse?.status) {
      try {
        setSign(ItemsOfferGetASINOffersGraphResponse?.data?.currency);

        setAsinWiseGraph(ItemsOfferGetASINOffersGraphResponse?.data?.records);
        setAsinWiseGraphLoading(false);
        fakeActionCatalog("ItemsOfferGetASINOffersGraphResponse");
      } catch (e) {
        console.error("Error", e);
      }
    } else if (ItemsOfferGetASINOffersGraphResponse?.status === false) {
      setAsinWiseGraph([]);
      setAsinWiseGraphLoading(false);
      fakeActionCatalog("ItemsOfferGetASINOffersGraphResponse");
    }
  }, [ItemsOfferGetASINOffersGraphResponse]);
  const listExtraProps = (type) => {
    return {
      defaultSortOrder:
        type === listSortFilters?.key?.replace("-", "")
          ? listSortFilters.type
          : [],
      sorter: () => {},
    };
  };

  const handleOnChangeColumn = (_, __, sorter) => {
    const sort =
      sorter?.order === "ascend"
        ? sorter?.column?.key
        : sorter?.order === "descend"
        ? `-${sorter?.column?.key}`
        : "";

    setLoading(true);
    setList([]);
    ItemsOfferTableDataAction({
      ...marketplaceSelected,
      asin: filters?.search_text,
      is_buybox_winner: filters?.is_buybox_winner,
      sort: sort,
    });

    setListSortFilters({
      key: sort,
      type: sorter?.order || "",
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "event_date",
      key: "event_date",
      ...listExtraProps("event_date"),
      render: (e) => <span>{e || "-"}</span>,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (e) => <Tag color="blue">{e}</Tag>,
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (e, row) => (
        <Tag
          onClick={() => {
            setSelectedRow(row);
            setEditView(true);
          }}
          color="cyan"
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          {e}
        </Tag>
      ),
    },
    {
      title: "Product Title",
      dataIndex: "item_name",
      key: "item_name",
      width: 400,
    },
    {
      title: "Product Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "BuyBox price",
      dataIndex: "listing_price",
      key: "listing_price",
      render: (e, r) => {
        console.log(r, "r");
        return (
          <div>
            {/* {sign} */}
            {SwapCommaAndDot(
              parseFloat(e || 0).toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id?.split("_")?.[0]
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Own Buy box winner",
    //   dataIndex: "is_own_buybox_winner",
    //   key: "is_own_buybox_winner",
    //   render: (e) => {
    //     return (
    //       <Tag color={parseInt(e) == 0 ? "red" : "green"}>
    //         {parseInt(e) == 0 ? "False" : "True"}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: "Buy Box Status",
      dataIndex: "is_buybox_winner",
      key: "is_buybox_winner",
      render: (e) => {
        return (
          <Tag color={e == 0 ? "red" : "green"}>
            {e == 0 ? "False" : "True"}
          </Tag>
        );
      },
    },
    {
      title: "Buy Box Seller Name",
      dataIndex: "seller_name",
      key: "seller_name",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Buy Box Seller",
      dataIndex: "seller_id",
      key: "seller_id",
    },
  ];

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();

    setSellerListLoading(true);
    ItemsOfferGetSellerList();
    return () => {};
  }, []);

  let options = {
    series: list?.map((d) => parseFloat(d?.listing_price)),
    chart: {
      type: "pie",
    },
    labels: list?.map((d) => d?.seller_name),
    tooltip: {
      enabled: true,
      theme: "dark",
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        console.log(series[seriesIndex], "series[seriesIndex]");
        return `<div style="padding: 7px;">${
          w.config.labels?.[seriesIndex] || "-"
        }: ${SwapCommaAndDot(
          parseFloat(series[seriesIndex] || 0).toLocaleString(),
          sign,
          "",
          marketplaceSelected?.marketplace_id?.split("_")?.[0]
        )}</div>`;
      },
    },
  };

  const options_ = {
    chart: {
      type: "scatter",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      y: {
        formatter: (value) =>
          SwapCommaAndDot(
            parseFloat(value || 0).toLocaleString(),
            sign,
            "",
            marketplaceSelected?.marketplace_id?.split("_")?.[0]
          ), // Add prefix here
      },
    },
    xaxis: {
      categories: asinWiseGraph?.chartDate?.map((d) =>
        moment(d, "YYYY-MM-DD").format("DD, MMM YYYY")
      ),
    },
    yaxis: {
      // tickAmount: 7,
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const series = asinWiseGraph?.chart_data?.map((d) => ({
    name: d?.name,
    data: d?.data?.map((r) => parseFloat(r?.price)),
  }));

  const onPageNo = (e) => {
    setPage(e);
    setLoading(true);

    ItemsOfferTableDataAction({
      page: e,
      perPage: pageSize,
      asin: filters?.search_text,
      ...marketplaceSelected,
      is_buybox_winner: filters?.is_buybox_winner,
    });
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    ItemsOfferTableDataAction({
      page: page,
      perPage: e,
      ...marketplaceSelected,
      is_buybox_winner: filters?.is_buybox_winner,
      asin: filters?.search_text,
    });
  };

  if (editView) {
    return (
      <AsinView
        onClose={() => setEditView(false)}
        sellerList={sellerList}
        selectedRow={selectedRow}
        sellerListLoading={sellerListLoading}
        marketplaceSelected={marketplaceSelected}
        {...props}
      />
    );
  }

  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-5 fix-over">
        <div className="row" style={{ columnGap: "20px", marginLeft: "0px" }}>
          <div
            className="col d-grid"
            style={{
              background: "rgb(254 243 242)",
              border: "1px solid rgb(191 96 90)",
              padding: "10px 10px",
              borderRadius: "10px",
            }}
          >
            <h6 style={{ color: "#158310", fontSize: "14px" }}>
              {prValue?.live_products?.percentage || 0}% Live Products
            </h6>
            <small>
              Total Catalog products:{" "}
              {prValue?.live_products?.totalCatalogProducts || 0}
            </small>
            <small>
              Total Products for sale:{" "}
              {prValue?.live_products?.totalProductSale || 0}
            </small>
          </div>
          <div
            className="col d-grid"
            style={{
              background: "rgb(254 243 242)",
              border: "1px solid rgb(191 96 90)",
              padding: "10px 10px",
              borderRadius: "10px",
            }}
          >
            <h6 style={{ color: "#0b66d2", fontSize: "14px" }}>
              {prValue?.own_buybox_winner?.percentage || 0}% of Buyboxes win
            </h6>
            <small>
              Total Products for sales:{" "}
              {prValue?.own_buybox_winner?.totalProductSales || 0}
            </small>
            <small>
              Total Buyboxes win: {prValue?.own_buybox_winner?.buybox_won || 0}
            </small>
          </div>
          <div
            className="col d-grid"
            style={{
              background: "rgb(254 243 242)",
              border: "1px solid rgb(191 96 90)",
              padding: "10px 10px",
              borderRadius: "10px",
            }}
          >
            <h6 style={{ color: "#da5635", fontSize: "14px" }}>
              {prValue?.other_buybox_winner?.percentage || 0}% of Buyboxes win
              by others Sellers
            </h6>
            <small>
              Total Products for sale:{" "}
              {prValue?.other_buybox_winner?.totalProductSales || 0}
            </small>
            <small>
              Total Buyboxes win:{" "}
              {prValue?.other_buybox_winner?.buybox_won || 0}
            </small>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-5 fix-over">
        <div className="d-flex align-items-center flex-wrap fix-over">
          <Select
            style={{
              width: 190,
            }}
            size="large"
            allowClear
            className="me-3"
            placeholder="Status"
            onChange={(e) => {
              setLoading(true);
              setList([]);
              ItemsOfferTableDataAction({
                region: marketplaceSelected.region,
                sales_channel: marketplaceSelected.sales_channel,
                account_type: marketplaceSelected.account_type,
                asin: filters?.search_text,
                is_own_winner: filters?.is_own_winner,
                status: e || null,
                is_buybox_winner: filters?.is_buybox_winner,
              });
              ChartAPIDataAction({
                region: marketplaceSelected.region,
                sales_channel: marketplaceSelected.sales_channel,
                asin: filters?.search_text,
                is_own_winner: filters?.is_own_winner,
                status: e || null,
                account_type: marketplaceSelected.account_type,
                is_buybox_winner: filters?.is_buybox_winner,
              });
              ItemsOfferGetASINOffersGraph({
                ...marketplaceSelected,
                asin: filters?.search_text,
                is_own_winner: filters?.is_own_winner,
                status: e || null,
                is_buybox_winner: filters?.is_buybox_winner,
              });
              setFilters({ ...filters, status: e || null });
            }}
            options={[
              {
                label: "Active",
                value: "Active",
              },
              {
                label: "Inactive",
                value: "Inactive",
              },
            ]}
          />
          <div className="w-250px ms-3">
            <b>BuyBox Status&nbsp;&nbsp;</b>
            <Segmented
              value={filters?.is_buybox_winner}
              options={[
                {
                  label: "True",
                  value: "1",
                },
                {
                  label: "False",
                  value: "0",
                },
              ]}
              onChange={(value) => {
                setLoading(true);
                setList([]);
                ItemsOfferTableDataAction({
                  region: marketplaceSelected.region,
                  sales_channel: marketplaceSelected.sales_channel,
                  account_type: marketplaceSelected.account_type,
                  asin: filters?.search_text,
                  is_own_winner: filters?.is_own_winner,
                  is_buybox_winner: value,
                });
                ChartAPIDataAction({
                  region: marketplaceSelected.region,
                  sales_channel: marketplaceSelected.sales_channel,
                  asin: filters?.search_text,
                  is_own_winner: filters?.is_own_winner,
                  is_buybox_winner: value,
                  account_type: marketplaceSelected.account_type,
                });
                ItemsOfferGetASINOffersGraph({
                  ...marketplaceSelected,
                  asin: filters?.search_text,
                  is_own_winner: filters?.is_own_winner,
                  is_buybox_winner: value,
                });
                setFilters({ ...filters, is_buybox_winner: value || "" });
              }}
            />
          </div>
          <Select
            style={{
              width: 190,
            }}
            size="large"
            allowClear
            className="me-3"
            placeholder="Winners"
            onChange={(e) => {
              setLoading(true);
              setList([]);
              ItemsOfferTableDataAction({
                region: marketplaceSelected.region,
                sales_channel: marketplaceSelected.sales_channel,
                account_type: marketplaceSelected.account_type,
                asin: filters?.search_text,
                is_own_winner: e || "",
                is_buybox_winner: filters?.is_buybox_winner,
              });
              ChartAPIDataAction({
                region: marketplaceSelected.region,
                sales_channel: marketplaceSelected.sales_channel,
                asin: filters?.search_text,
                is_own_winner: e || "",
                account_type: marketplaceSelected.account_type,
                is_buybox_winner: filters?.is_buybox_winner,
              });
              ItemsOfferGetASINOffersGraph({
                ...marketplaceSelected,
                asin: filters?.search_text,
                is_own_winner: e || "",
                is_buybox_winner: filters?.is_buybox_winner,
              });
              setFilters({ ...filters, is_own_winner: e || "" });
            }}
            options={[
              {
                label: <span>Me</span>,
                value: "1",
              },
              {
                label: <span>Others</span>,
                value: "0",
              },
            ]}
          />

          <Input
            placeholder="Search by ASIN"
            onChange={(e) =>
              setFilters({ ...filters, search_text: e.target.value })
            }
            value={filters?.search_text}
            size="large"
            onPressEnter={() => {
              setLoading(true);
              setList([]);
              ItemsOfferTableDataAction({
                region: marketplaceSelected.region,
                sales_channel: marketplaceSelected.sales_channel,
                account_type: marketplaceSelected.account_type,
                asin: filters?.search_text,
                is_buybox_winner: filters?.is_buybox_winner,
              });
              ChartAPIDataAction({
                region: marketplaceSelected.region,
                sales_channel: marketplaceSelected.sales_channel,
                asin: filters?.search_text,
                is_buybox_winner: filters?.is_buybox_winner,
                account_type: marketplaceSelected.account_type,
              });

              ItemsOfferGetASINOffersGraph({
                ...marketplaceSelected,
                asin: filters?.search_text,
                is_buybox_winner: filters?.is_buybox_winner,
              });
            }}
            className="w-200px me-3"
          />
          <Select
            className="w-200px "
            size="large"
            allowClear={false}
            getPopupContainer={(target) => target.parentNode}
            options={marketplaceList}
            onChange={(_, e) => {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              setLoading(true);
              setList([]);
              ItemsOfferTableDataAction({
                region: e.region,
                asin: filters?.search_text,
                sales_channel: e.sales_channel,
                account_type: e.account_type,
                is_buybox_winner: filters?.is_buybox_winner,
              });
              ChartAPIDataAction({
                region: e.region,
                asin: filters?.search_text,
                sales_channel: e.sales_channel,
                is_buybox_winner: filters?.is_buybox_winner,
                account_type: e.account_type,
              });
            }}
            value={marketplaceSelected?.marketplace_id}
            loading={marketplaceLoading}
            placeholder="Select Marketplace"
          />
          <Button
            size="large"
            className="ms-3"
            onClick={() => {
              message.destroy();
              message.loading("Loading...", 0);
              ItemsOfferTableDataExportAction({
                ...marketplaceSelected,
                asin: filters?.search_text,
                is_buybox_winner: filters?.is_buybox_winner,
              });
            }}
            type="primary"
          >
            Export
          </Button>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Table
            columns={columns}
            dataSource={list}
            loading={loading}
            pagination={false} // You can enable pagination if needed
            scroll={{ x: "max-content" }}
            onChange={handleOnChangeColumn}
          />
          <Pagination
            loading={loading || list?.length === 0}
            pageSize={pageSize}
            pageSizeStatus={false}
            page={page}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
          />
        </div>
      </div>
      <div
        className="row mt-5"
        style={{ display: graphData?.length === 0 ? "none" : "" }}
      >
        <div className="col-6">
          <div className="card">
            <div className="card-body h-400px">
              {loading ? (
                <div className="h-400px d-flex align-items-center justify-content-center">
                  <Spin />
                </div>
              ) : graphData?.length === 0 ? (
                <div className="h-400px d-flex align-items-center justify-content-center">
                  <Empty />
                </div>
              ) : (
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="pie"
                  height={350}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-body  h-400px">
              {loading ? (
                <div className="h-400px d-flex align-items-center justify-content-center">
                  <Spin />
                </div>
              ) : graphData?.length === 0 ? (
                <div className="h-400px d-flex align-items-center justify-content-center">
                  <Empty />
                </div>
              ) : (
                <ReactApexChart
                  options={options_}
                  series={series}
                  type="scatter"
                  height={350}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Tools;
