import Icons from "../components/icons";
import { convertSidebarLinks } from "../core/helper/static/sidebar";
export const ENDPOINT = "https://app.getmarketforce.it/marketforce_api/";
export const API_URL = `${ENDPOINT}api/v1/`;
export const KeepaGraph = "";
export const AmazonawsList = "";
export const DownloadUrl = `${ENDPOINT}uploads/`;
export const AppId = "";
// export const planToken = "pk_test_FgJ2NUlni0eehEtRDcLOdXvx00NmYpDbHt";
export const planToken = "";
export const pageDropdown = [10, 20, 50, 100];
export const DefaultPerPage = 20;
export const DefaultKeywordPerPage = 20;
export const DateFormat = "MMM DD, YYYY";

export const APPID = "amzn1.sp.solution.816ca9de-7ac8-472f-b628-5b5bb2e8736b";
export const ADVERTISING_CLIENT_ID =
  "amzn1.application-oa2-client.fd6270e7258c4160b3e724421676ce24";
export const ReturnURL = "https://app.getmarketforce.it/callbackadsapi";

export const priceCommonSign = "£";
export const priceSign = {
  "United States(Amazon.com)": "$",
  "US(Amazon.com)": "$",
  USA: "$",
  "UK(Amazon.co.uk)": "£",
  "United Kingdom": "£",
  "Germany(Amazon.de)": "€",
  Germany: "€",
  "France(Amazon.fr)": "€",
  France: "€",
  "Canada(Amazon.ca)": "$",
  "Italy(Amazon.it)": "€",
  Italy: "€",
  "Spain(Amazon.es)": "€",
  Spain: "€",
  "India(Amazon.in)": "₹",
  "Mexico(Amazon.com.mx)": "$",
  Brazil: "$",
  Canada: "$",
  // Mexico: ,
  // 'United Arab Emirates (U.A.E.)': ,
  // India: ,
  // Netherlands: ,
  // 'Saudi Arabia': ,
  // Turkey: ,
  // Singapore: ,
  // Australia: ,
  // Japan: ,
};
export const checkPermission = (contextValue, name, subName) => {
  if (localStorage.getItem("role_configuration") === "null") {
    return false;
  }

  const config =
    contextValue?.data?.role_configuration ||
    (localStorage.getItem("role_configuration") &&
      JSON.parse(localStorage.getItem("role_configuration")));

  const findData = config?.config ? JSON.parse(config?.config) : {};
  return !JSON.parse(findData?.[name]?.[subName]?.status || false);
};
const marketplaceConfig = {
  A3N624E90WFCX: {
    name: "mobilirebecca_2015",
    locale: "it-IT",
    currency: "EUR",
  },
  A1AT7YVPFBWXBL: { name: null, locale: "en-US", currency: "USD" },
  A1C00BZWZ6J9ZQ: { name: null, locale: "en-US", currency: "USD" },
  A1EO65LS5OVUTU: { name: null, locale: "en-US", currency: "USD" },
  A2EL6K6KDM9FO1: { name: "Amazon UK", locale: "en-GB", currency: "GBP" },
  A17D2BRD4YMT0X: { name: null, locale: "en-US", currency: "USD" },
  A11IL2PNWYJU7H: { name: null, locale: "en-US", currency: "USD" },
  A3R2BN8GAL312Z: { name: "Amazon UK", locale: "en-GB", currency: "GBP" },
  A1X6FK5RDHNB96: { name: null, locale: "en-US", currency: "USD" },
  ATQZ2A1KZQ0X2: { name: "Amazon UK", locale: "en-GB", currency: "GBP" },
  A2R2221NX79QZP: { name: null, locale: "en-US", currency: "USD" },
  A3JWKAKR8XB7XF: { name: null, locale: "en-US", currency: "USD" },
  A301WKE65PGVT5: { name: null, locale: "en-US", currency: "USD" },
  ANU9KP01APNAG: { name: null, locale: "en-US", currency: "USD" },
  AYHMIZVKTZBRN: { name: "EBSpol", locale: "pl-PL", currency: "PLN" },
  A336799MYEF4OT: { name: "", locale: "en-US", currency: "USD" },
  APUDM4I2TYYAL: { name: "", locale: "en-US", currency: "USD" },
  A30V8L4VAXHXAB: { name: "", locale: "en-US", currency: "USD" },
};

export const FormatCurrency = (amount, marketplaceId) => {
  console.log(marketplaceId, "marketplaceId");
  const { locale, currency } = marketplaceConfig[marketplaceId] || {
    locale: "en-US",
    currency: "USD",
  };
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(
    amount
  );
};

export const SwapCommaAndDot = (inputString, sign, pr, it) => {
  if (
    "APJ6JRA9NG5V4" !== it?.split("_")?.[0] &&
    "All" !== it?.split("_")?.[0]
  ) {
    return sign + inputString + pr;
  }
  let stringWithoutCommas = inputString.replace(/,/g, "@");

  let stringWithoutDots = stringWithoutCommas.replace(/\./g, ",");

  let resultString = stringWithoutDots.replace(/@/g, ".");

  return resultString + sign + pr;
};
export const numberformat = (value) => {
  if (value === "-") return 0;
  return parseFloat(value || 0).toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};
export const clearLocalData = () => {
  localStorage.clear();
  window.location.assign("/login");
  window.location.reload();
};

export const handleStorageChange = (e) => {
  e.stopPropagation();
  if (e.url === window.location.href) {
    if (!e.key) {
      clearLocalData();
    } else {
      localStorage.setItem(e.key, e.oldValue);
    }
  }
  if (
    !localStorage.getItem("token") ||
    !localStorage.getItem("user") ||
    !localStorage.getItem("userType") ||
    !localStorage.getItem("menusList")
  ) {
    clearLocalData();
  }
};

export const marketplaceVAT = {
  A1F83G8C2ARO7P: 20, // UK
  // A1F83G8C2AROUS: 5,
  A1PA6795UKMFR9: 19, // Germony
  A13V1IB3VIYZZH: 21, // France
  A1RKKUPIHCS9HS: 21, // Spain
  APJ6JRA9NG5V4: 22,
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date * 1000) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};

export const updateDate = (date_range) => {
  localStorage.setItem("date_range", JSON.stringify(date_range));
};

// US - $
// Mexico - $
// Canada - $

// Germony - €
// France -  €
// Italy - €
// Spain - €

// Uk -  £

// India -  ₹

// New Code
export const columnCommFunction = (
  isChange,
  Sign,
  marketplaceSign,
  marketplaceName,
  key1,
  key2,
  withDecimal
) => {
  {
    if (!key1 && !key2) {
      return (
        <span>
          {Sign === "currency" ? marketplaceSign : ""}
          {Sign === "has" ? "#" : ""} 0 {Sign === "percent" ? "%" : ""}
        </span>
      );
    } else if (!isChange) {
      return (
        <span>
          {Sign === "currency" ? marketplaceSign : ""}
          {Sign === "has" ? "#" : ""} {key1 || key2}
          {Sign === "percent" ? "%" : ""}
        </span>
      );
    } else if (
      typeof key1 === "number" ||
      key2 === "number" ||
      !isNaN(parseInt(key1 || key2))
    ) {
      if (withDecimal) {
        let number = key1 || key2 || 0;
        const decimalValue = number / 100;
        return (
          <span>
            {Sign === "currency" ? marketplaceSign : ""}
            {Sign === "has" ? "#" : ""}
            &nbsp;
            {decimalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {Sign === "percent" ? "%" : ""}
          </span>
        );
      } else if (!withDecimal) {
        return (
          <span>
            {Sign === "currency" ? marketplaceSign : ""}
            {Sign === "has" ? "#" : ""}
            &nbsp;
            {(key1 || key2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {Sign === "percent" ? "%" : ""}
          </span>
        );
      }
    } else {
      return <span>{key1 || key2}</span>;
    }
  }
};

export const testArr = [
  "dashboard",
  "item-master",
  "keyword-tracker",
  "listing-optimizer",
  "inventory",
  "master-table",
  "inventory-management",
  "sp-api-reports",
  "repricing",
  "inventory-forecasting",
  "data-scheduler",
  "competitor-research",
  "profitability-calculator",
  "bulk-profitability-calculator",
  "sales-analytics",
  "manage-company-employee",
  "wholesale-supplier-directory",
  "wholesale-supplier-list",
  "product-research",
  "competitor-search-analytics",
  "campaign-management",
  "brand-protection",
  "pricing-plans",
  "payment-history",
  "feedback-automation",
  // "profile",
  "reimbursement",
  "brand-wise-analytics",
  "my-list",
  "setting",
  "application-logs",
  "catalog",
];

export const defaultMenus = ["pricing-plans", "setting"];
export const adminMenus = [
  "manage-seller-users",
  "estimated-sales",
  "wholesale-supplier-directory",
  "categories",
  "application-logs",
  "user-feedback",
  "manage-subscription",
  "wholesale-supplier-list",
  "setting",
];

const bullet = (
  <span className="menu-bullet">
    <span className="bullet bullet-dot" />
  </span>
);

const GetIcons = (icon) => {
  return <Icons type={icon} />;
};
export const AllMarketPlace = {
  label: (
    <>
      <img
        src={`/domainImage/ALL.png`}
        style={{ height: "20px" }}
        loading="lazy"
        className="me-3"
        onError={(e) => {
          e.target.src = "/media/domainImage/red-flag.png";
        }}
      />
      All
    </>
  ),
  value: "all",
  region: "all",
  marketplace_id: "All",
  marketplace: "all",
  sales_channel: "",
};
const appLogs = [
  {
    title: "Central log system",
    name: "central-log-system",
    path: ["/central-log-system"],
    index: [],
    parent: "application-logs",
    icon: bullet,
  },
  {
    title: "System event log",
    name: "system-event-log",
    path: ["/system-event-log"],
    index: [],
    parent: "application-logs",
    icon: bullet,
  },
];

const Modules = [
  // admin end
  // user start
  {
    title: "Manage User/Client",
    name: "manage-user",

    path: ["/manage-user"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("manage-seller-user"),
  },
  {
    title: "Manage Admin",
    name: "manage-admin",

    path: ["/manage-admin"],
    index: [
      {
        admin: 2,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("admin-data"),
  },
  {
    title: "Criteria",
    name: "criteria",

    path: ["/criteria"],
    index: [
      {
        admin: 4,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("brand-analytics"),
  },
  {
    title: "Embed Codes",
    name: "embed-codes",

    path: ["/embed-codes"],
    index: [
      {
        admin: 4,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: (
      <svg
        width={23}
        height={23}
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M15.778 4.618a.767.767 0 1 0-1.423-.57L8.22 19.383a.767.767 0 1 0 1.424.57l6.133-15.334Zm-9.37 3.773c.3.3.3.785 0 1.085L3.885 12l2.525 2.525a.767.767 0 1 1-1.084 1.084l-3.067-3.067c-.3-.3-.3-.785 0-1.084l3.067-3.067c.299-.3.784-.3 1.084 0Zm11.183 0c.3-.3.785-.3 1.084 0l3.067 3.067c.3.3.3.785 0 1.084l-3.067 3.067a.767.767 0 0 1-1.084-1.084L20.116 12 17.59 9.476c-.3-.3-.3-.785 0-1.085Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  // {
  //   title: "Manage Agency",
  //   name: "manage-agency",
  //   path: ["/manage-agency"],
  //   index: [
  //     {
  //       admin: 3,
  //       common: -1,
  //     },
  //   ],
  //   rule: ["admin"],
  //   icon: GetIcons("company-data"),
  // },
  {
    title: "Analytics",
    name: "analytics",

    path: ["/", "/analytics"],
    index: [
      {
        admin: -1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("dashboard"),
    children: [
      {
        title: "Overview",
        name: "overview",
        path: ["/overview"],
        index: [],
        parent: "analytics",
        icon: bullet,
      },
      {
        title: "Performance",
        name: "performance",
        path: ["/performance"],
        index: [],
        parent: "analytics",
        icon: bullet,
      },
      {
        title: "Product Details",
        name: "product-details",
        path: ["/product-details"],
        index: [],
        parent: "analytics",
        icon: bullet,
      },
      {
        title: "Vendor Performance",
        name: "vendor-performance",
        path: ["/vendor-performance"],
        index: [],
        parent: "analytics",
        icon: bullet,
      },
      {
        title: "Report Account",
        name: "report-account",
        path: ["/report-account"],
        index: [],
        parent: "analytics",
        icon: bullet,
      },
      {
        title: "Advertising Account",
        name: "advertising-account",
        path: ["/advertising-account"],
        index: [],
        parent: "analytics",
        icon: bullet,
      },
    ],
  },
  {
    title: "Account Status",
    name: "account-status",

    path: [],
    parentPath: "/account-status",
    index: [
      {
        admin: -1,
        common: 2,
      },
    ],
    rule: ["common"],
    icon: GetIcons("menuSideBar"),
  },
  {
    title: "Vendor Order",
    name: "vendor-order",
    path: [],
    parentPath: "/vendor-order",
    index: [
      {
        admin: -1,
        common: 17,
      },
    ],
    rule: ["common"],
    icon: GetIcons("menuSideBar"),
  },
  {
    title: "Reports",
    name: "reports",

    path: ["/reports"],
    index: [
      {
        admin: -1,
        common: 3,
      },
    ],
    rule: ["common"],
    icon: GetIcons("reports"),
  },
  {
    title: "Inventory",
    name: "inventory",

    path: ["/inventory"],
    index: [
      {
        admin: -1,
        common: 4,
      },
    ],
    rule: ["common"],
    icon: GetIcons("inventory"),
  },
  {
    title: "Brand Analytics",
    name: "brand-analytics",

    path: ["/brand-analytics"],
    index: [
      {
        admin: -1,
        common: 5,
      },
    ],
    rule: ["common"],
    icon: GetIcons("brand-analytics"),
  },

  // {
  //   title: "Amazon Catalogue Health",
  //   name: "amazon-catalogue-health",
  //   path: ["/amazon-catalogue-health"],
  //   index: [
  //     {
  //       admin: -1,
  //       common: 8,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: GetIcons("amazon-catalogue-health"),
  // },
  {
    title: "Catalog",

    name: "catalog",

    parentPath: "/catalog",
    path: ["/catalog"],
    index: [
      {
        admin: -1,
        common: 9,
      },
    ],
    rule: ["common"],
    icon: GetIcons("amazon-catalogue-health"),

    children: [
      {
        title: "Overview",
        name: "over-view",
        path: ["/over-view"],
        index: [],
        parent: "catalog",
        icon: bullet,
      },
      {
        title: "Catalog",
        name: "catalog",
        path: ["/catalog"],
        index: [],
        parent: "catalog",
        icon: bullet,
      },
      {
        title: "Bulk Catalog Upload",
        name: "bulk-catalog-upload",
        path: ["/bulk-catalog-upload"],
        index: [],
        parent: "catalog",
        icon: bullet,
      },

      {
        title: "Amazon Catalog Health",
        name: "amazon-catalog-health",
        path: ["/amazon-catalog-health"],
        index: [],
        parent: "catalog",
        icon: bullet,
      },

      {
        title: "Amazon Quality Score",
        name: "amazon-quality-score",
        path: ["/amazon-quality-score"],
        index: [],
        parent: "catalog",
        icon: bullet,
      },
    ],
  },

  {
    title: "Brand Protection",
    name: "brand-protection",
    path: ["/brand-protection"],
    index: [
      {
        admin: -1,
        common: 10,
      },
    ],
    rule: ["common"],
    icon: GetIcons("brand_protection"),
    children: [
      {
        title: "Amazon Buybox",
        name: "amazon-buybox",
        path: ["/amazon-buybox"],
        index: [],
        parent: "catalog",
        icon: bullet,
      },
      {
        title: "Reseller Monitor",
        name: "reseller-monitor",
        path: ["/reseller-monitor"],
        index: [],
        parent: "catalog",
        icon: bullet,
      },
      {
        title: "Brand Monitor",
        name: "brand-monitor",
        path: ["/brand-monitor"],
        index: [],
        parent: "catalog",
        icon: bullet,
      },
    ],
  },
  {
    title: "Campaign Management",
    name: "campaign-management",
    path: ["/campaign-management"],
    index: [
      {
        admin: -1,
        common: 10,
      },
    ],
    rule: ["common"],
    icon: GetIcons("product-research"),
  },
  {
    title: "Finance",
    name: "finance",
    path: ["/finance"],
    index: [
      {
        admin: -1,
        common: 11,
      },
    ],
    rule: ["common"],
    icon: GetIcons("finance"),
  },
  {
    title: "Customer sentiment",

    name: "customer-sentiment",
    path: ["/customer-sentiment"],
    index: [
      {
        admin: -1,
        common: 12,
      },
    ],
    rule: ["common"],
    icon: GetIcons("customer-sentiment"),
  },
  // {
  //   title: "Vendor",
  //   name: "vendor",
  //   path: ["/vendor"],
  //   index: [
  //     {
  //       admin: -1,
  //       common: 12,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: GetIcons("vendor"),
  // },
  // {
  //   title: "Advertising Analytics",
  //   name: "advertising-analytics",
  //   path: ["/advertising-analytics"],
  //   index: [
  //     {
  //       admin: -1,
  //       common: 13,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: GetIcons("advertising-analytics"),
  // },
  // {
  //   title: "Agency",
  //   name: "agency",
  //   path: ["/agency"],
  //   index: [
  //     {
  //       admin: -1,
  //       common: 14,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: GetIcons("agency"),
  // },
  // {
  //   title: "Client Data",
  //   name: "client-data",
  //   path: ["/client-data"],
  //   index: [
  //     {
  //       admin: -1,
  //       common: 15,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: GetIcons("client-data"),
  // },

  {
    title: "Setting",
    name: "setting",

    path: [],
    parentPath: "/setting",
    index: [
      {
        admin: -1,
        common: 15,
      },
    ],
    rule: ["common"],
    icon: GetIcons("settings"),
  },
  {
    title: "Setting",
    name: "setting",

    path: [],
    parentPath: "/setting",
    index: [
      {
        admin: 4,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("settings"),
    // children: [
    //   {
    //     title: "Super Admin",
    //     name: "super-admin",
    //     path: ["/super-admin"],
    //     index: [],
    //     parent: "setting",
    //     icon: bullet,
    //   },
    //   {
    //     title: "User",
    //     name: "user",
    //     path: ["/user"],
    //     index: [],
    //     parent: "setting",
    //     icon: bullet,
    //   },
    //   {
    //     title: "Client",
    //     name: "client",
    //     path: ["/client"],
    //     index: [],
    //     parent: "setting",
    //     icon: bullet,
    //   },
    // ],
  },
  {
    title: "Application Logs",
    name: "application-logs",

    path: [],
    parentPath: "/application-logs",
    index: [
      {
        admin: -1,
        common: 16,
      },
    ],
    rule: ["common"],
    icon: GetIcons("payment-history"),
    children: [
      {
        title: "Central Log",
        name: "central-log",
        path: ["/central-log"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
      {
        title: "System Log",
        name: "system-log",
        path: ["/system-log"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
    ],
  },
];

export const MyNewSidebarFunc = (userMenus, mode) => {
  if (mode === "admin") {
    return userMenus;
  }
  const menuList = localStorage.getItem("menus")
    ? JSON.parse(localStorage.getItem("menus")) || []
    : [];
  let menus = [];
  userMenus.forEach((d) => {
    const index = menuList?.findIndex((a) => a === d.key);
    if (index !== -1) {
      menus.push(d);
    }
  });
  return menus;
};

export const ModulesList = (props) => {
  const { type, mode } = props;
  if (type === "sidebar") {
    return MyNewSidebarFunc(
      convertSidebarLinks(
        Modules?.filter((d) => d?.rule?.findIndex((t) => t === mode) !== -1),
        mode
      ),
      mode
    );
  } else if (type === "url") {
    let obj = {};
    Modules.forEach((r) => {
      r.path.forEach((p) => {
        obj = {
          ...obj,
          [p]: {
            name: r.title,
            parent: r.parentName,
            path: r.parentPath,
          },
        };
      });
      r?.children?.forEach((p) => {
        p.path.forEach((i) => {
          obj = {
            ...obj,
            [i]: {
              name: p?.title,
              path: r?.parentPath + i,
              parent: r?.title,
            },
          };
        });
      });
    });
    return obj;
  }
  return [];
};

const downloadFile = (href, name) => {
  const link = document.createElement("a");
  link.href = `${href}${name}`;
  link.setAttribute("download", name);
  link.click();
  link.remove();
};

const findBySKUFile = (localVat) => {
  const { vat_type, vat_on_cost, vat_on_sale } = localVat;
  if (vat_type === "standard_rate") {
    if (vat_on_cost == 1 && vat_on_sale == 1) {
      return "SKUDataSampleTemplate1.xlsx";
    } else if (vat_on_cost == 1 && vat_on_sale == 0) {
      return "SKUDataCost1Sale0.xlsx";
    } else if (vat_on_cost == 0 && vat_on_sale == 1) {
      return "SKUDataCost0Sale1.xlsx";
    } else {
      return "SKUDataCost0Sale0.xlsx";
    }
  } else if (vat_type === "flat_rate") {
    return "SKUDataFlatRate.xlsx";
  }
  return "SKUDataNotRegistered.xlsx";
};

export const skuDataSampleExport = (localVat) => {
  const prefix = "/media/skuDataExport/";
  downloadFile(prefix, findBySKUFile(localVat));
};

export const bulkProfitSampleExport = (localVat) => {
  // default
  return downloadFile(
    "/media/bulkProfitExport/",
    "BulkProfitSampleTemplate.xlsx"
  ); // changes by fenali
};

export const vatConfig = () => {
  const { vat, vat_on_cost, vat_on_sale, vat_type, vat_on_rate } = JSON.parse(
    localStorage.getItem("user")
  );
  return { vat, vat_on_cost, vat_on_sale, vat_type, vat_on_rate };
};

const getIds = (type, marketplaceList, d, getType, labelType) => {
  const marketplace = marketplaceList?.[d];

  return type === "number" // for domain
    ? getType === "values"
      ? d || ""
      : marketplace || ""
    : getType === "label"
    ? labelType === "sales_channel" // for sales channel
      ? marketplace?.marketplace_id === null
        ? marketplace?.sales_channel || ""
        : `${marketplace?.marketplace}(${marketplace?.sales_channel})` // for sales channel
      : `${marketplace?.marketplace_name}(${marketplace?.sales_channel})` // for sales marketplace by credential
    : marketplace?.marketplace_id || marketplace?.sales_channel; // for value of marketplace by cred and sales channel
};

export const createOption = (e, all = false) => {
  const defaultImageSrc = "/media/domainImage/red-flag.png";
  const imageUrl = all
    ? `/domainImage/ALL.png`
    : `/domainImage/${e?.marketplace_id.slice(0, -1)}.png`;

  return {
    label: (
      <>
        <img
          src={imageUrl}
          alt=""
          style={{ height: "20px" }}
          loading="lazy"
          className="me-3"
          onError={(e) => (e.target.src = defaultImageSrc)}
        />
        {all ? "All" : `${e?.short_name} (${e.sales_channel})`}
      </>
    ),
    value: all ? `all${e.region_label}` : e.marketplace_id,
    ...(!all && {
      region: e.region,
      marketplace_id: e.marketplace_id,
      marketplace: e.marketplace,
      sales_channel: e.sales_channel,
    }),
  };
};

export const MarketplaceFlag = (
  marketplaceList,
  type = "number",
  labelType = "default"
) =>
  Object.keys(marketplaceList).map((d, i) => {
    const obj_ = type === "number" ? d : marketplaceList?.[d];
    return {
      value: getIds(type, marketplaceList, d, "values", labelType),
      label: (
        <>
          <img
            src={`/media/domainImage/${getIds(
              type,
              marketplaceList,
              d,
              "image",
              labelType
            )}.png`}
            style={{ height: "20px" }}
            loading="lazy"
            className="me-3"
            onError={(e) => {
              e.target.src = "/media/domainImage/red-flag.png";
            }}
          />
          {getIds(type, marketplaceList, d, "label", labelType)}
        </>
      ),
      ...obj_,
    };
  });

export const setValueColor = () => {
  let numbers = document.querySelectorAll(".CurrencyVal");

  numbers.forEach(function (element) {
    let value = element.getElementsByClassName("value")?.[0]?.textContent;

    element.style.color = value > 0 ? "green" : value == 0 ? "" : "red";
    // element.style.color = value === 0 ? "black" : value < 0 ? "red" : "green";
  });
};
export const NumberWithCommas = (n, sign) => {
  const num_ = parseFloat(n || 0);
  if (num_) {
    if (Number(num_) === num_ && num_ % 1 !== 0) {
      return `${parseFloat(num_)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    return `${sign || ""}${parseInt(num_)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`?.replace(
      `${sign || ""}-`,
      `-${sign || ""}`
    );
  }
  return `${sign || ""}${num_}`?.replace(`${sign || ""}-`, `-${sign || ""}`);
};
