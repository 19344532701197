import { Badge, Card, Col, Progress, Row, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Wrapper } from "./style";
import { Icon } from "@iconify/react";

const OverView = ({
  fakeActionCatalog,
  SalesChannelListAction,
  GetCatalogOverviewAction,
}) => {
  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const GetCatelogOverviewResponse = useSelector(
    (state) => state.Catalog.GetCatelogOverviewResponse || {}
  );
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  useEffect(() => {
    if (GetCatelogOverviewResponse?.status === true) {
      setList(GetCatelogOverviewResponse?.data?.data || []);
      setLoading(false);
      fakeActionCatalog("GetCatelogOverviewResponse");
    } else if (GetCatelogOverviewResponse?.status === false) {
      setLoading(false);
      setList([]);
      fakeActionCatalog("GetCatelogOverviewResponse");
    }
  }, [GetCatelogOverviewResponse]);

  useEffect(() => {
    GetCatalogOverviewAction();

    return () => {};
  }, []);

  const SalesChannelCard = (props) => {
    const {
      country,
      active_products,
      productsLive,
      total_products,
      catalog_health,
      items_offer_per_active_product,
      marketplace,
      marketplace_id,
    } = props;

    const statusColor = parseInt(active_products) > 0 ? "success" : "error";

    return (
      <div className="col-xxl-4 col-lg-6 col-md-12">
        <Card
          title={country}
          bordered
          style={{
            borderColor: active_products ? "#78b272" : "#e27b7b",
            borderWidth: "2px",
            borderStyle: "dashed",
          }}
        >
          <div className="d-flex align-item-center justify-content-between">
            <div className=" d-flex align-items-center">
              <img
                src={`/media/domainImage/${marketplace_id}.png`}
                style={{ width: 25 }}
                onError={(e) => {
                  e.target.src =
                    "https://cdn.countryflags.com/thumbs/belgium/flag-round-500.png";
                }}
              />
              <span className="mx-5 fs-4">{marketplace}</span>
              <Tag color="blue">{marketplace_id}</Tag>
            </div>
            <Badge
              status={statusColor}
              text={`Status: ${active_products ? "Active" : "Inactive"}`}
            />
          </div>
          <div className="separator my-5"></div>
          <div className="gap-3 d-grid">
            <div className=" d-flex align-items-center justify-content-between">
              <span>Number of Products Live:</span>

              <span
                className="  rounded  p-2 px-3"
                style={{ border: "1px dashed #c8675b" }}
              >
                {(active_products || 0)?.toLocaleString()}
              </span>
            </div>

            <div className="  d-flex align-items-center justify-content-between">
              <span>Total Products:</span>
              <span
                className="  rounded  p-2 px-3"
                style={{ border: "1px dashed #c8675b" }}
              >
                {(total_products || 0)?.toLocaleString()}
              </span>
            </div>

            <div className=" d-flex align-items-center justify-content-between ">
              <span>Catalog Health:</span>
              <Progress
                type="dashboard"
                percent={parseInt(catalog_health)}
                size={37}
                status="active"
              />
            </div>

            <div
              className="  d-flex align-items-center justify-content-between"
              style={{ padding: "5.078px 0px" }}
            >
              <span>Amazon Buybox Performance:</span>
              <Progress
                className="w-100px"
                percent={parseInt(items_offer_per_active_product || 0)}
                size="small"
                status="active"
              />
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const SalesChannelsList = ({ channels }) => {
    return (
      <div className="row gy-5 mt-3">
        {channels.map((channel) => (
          <SalesChannelCard key={channel.country} {...channel} />
        ))}
      </div>
    );
  };

  const columns = [
    {
      title: "Sales Channel",

      render: (e) => {
        return (
          <span>
            Amazon.{e.account_type}.{e?.marketplace_short_name}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "active_products",
      key: "active_products",
      render: (e) => {
        return <Badge size={100} color={parseInt(e) > 0 ? "green" : "red"} />;
      },
    },
    {
      title: (
        <>
          Number or Products Live{" "}
          <Tooltip
            placement="bottom"
            title="Count of Active Products that are listed in Amazon"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),

      render: (e) => {
        return <span>{e?.active_products?.toLocaleString()}</span>;
      },
    },
    {
      title: (
        <>
          Total Products{" "}
          <Tooltip
            placement="bottom"
            title="Count of Products added from the Catalog Manual Import Products that includes Child and Single ASINs"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),

      render: (e) => {
        return (
          <span>
            {e?.catalog_data?.total_manual_porducts_count?.toLocaleString()}
          </span>
        );
      },
    },
    {
      title: (
        <>
          Catalog Health{" "}
          <Tooltip
            placement="bottom"
            title="Formulated as Product that are matched with Amazon / Total Product uploaded from Amazon * 100"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),
      dataIndex: "catalog_health",
      key: "catalog_health",
      align: "center",
      render: (e) => {
        return <Progress size={50} type="dashboard" percent={parseInt(e)} />;
      },
    },
    {
      title: (
        <>
          Amazon Buybox Performance{" "}
          <Tooltip
            placement="bottom"
            title="Formulated as Buy Box Winner Count / Amazon Catalog Active Product Count * 100"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),
      dataIndex: "items_offer_per_active_product",
      key: "items_offer_per_active_product",
      align: "center",
      width: 350,
      render: (e) => {
        return <Progress type="line" percent={parseInt(e || 0)} />;
      },
    },
  ];
  if (1 !== 1) {
    return <SalesChannelsList channels={list} />;
  }
  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="card">
        <div className="card-body">
          <Table
            loading={loading}
            scroll={{ x: "max-content" }}
            dataSource={list}
            pagination={false}
            columns={columns}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default OverView;
