import React, { useContext, useEffect } from "react";
import { Wrapper } from "../vendor-performance/style";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Chart from "react-apexcharts";
import { DatePicker, Select, Spin, message } from "antd";
import { useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../../commonContext";
import {
  AllMarketPlace,
  SwapCommaAndDot,
  updateDate,
} from "../../../../config";

const VendorOverview = ({
  fakeActionCatalog,
  SalesChannelListAction,
  GetCategoryAction,
  GetSubCategoryAction,
  fakeActionAnalytics,
  GetVendorAnalyticByChartAction,
  GetVendorAnalyticByCardAction,
}) => {
  const contextValue = useContext(GlobalContext);
  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
    category: null,
    sub_category: null,
  });
  const [sign, setSign] = useState("$");

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const [categoryList, setCategoryList] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(true);

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);

  const [cardRecords, setCardRecords] = useState({});
  const [cardLoading, setCardLoading] = useState(true);

  const [chartData, setChartData] = useState({});
  const [chartLoading, setChartLoading] = useState(true);
  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const GetCategoryListResponse = useSelector(
    (state) => state.Analytics.GetCategoryListResponse || {}
  );
  const GetSubCategoryListResponse = useSelector(
    (state) => state.Analytics.GetSubCategoryListResponse || {}
  );
  const GetVendorAnalyticsByCardResponse = useSelector(
    (state) => state.Analytics.GetVendorAnalyticsByCardResponse || {}
  );
  const GetVendorAnalyticsByChartResponse = useSelector(
    (state) => state.Analytics.GetVendorAnalyticsByChartResponse || {}
  );

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      const filteredValue = SalesChannelListResponse?.data?.records?.filter(
        (d) => d?.account_type === "Vendor"
      );
      if (filteredValue?.length > 0) {
        const salesList = filteredValue?.reduce((acc, e, i) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region_label);

          if (i === 0) {
            setMarketplaceSelected({
              marketplace: e?.marketplace,
              marketplace_id: e?.marketplace_id,
              region: e?.region,
              sales_channel: e?.sales_channel,
            });
            setCardLoading(true);
            GetVendorAnalyticByCardAction({
              region: e?.region,
              sales_channel: e?.sales_channel,
              ...filters,
            });
            setChartLoading(true);
            GetVendorAnalyticByChartAction({
              region: e?.region,
              sales_channel: e?.sales_channel,
              ...filters,
            });
            setCategoryLoading(true);
            GetCategoryAction({
              region: e?.region,
              sales_channel: e?.sales_channel,
            });
            setSubCategoryLoading(true);
            GetSubCategoryAction({
              region: e?.region,
              sales_channel: e?.sales_channel,
            });
          }

          if (regionIndex === -1) {
            acc.push({
              label: e.region_label,
              options: [
                {
                  label: (
                    <>
                      <img
                        src={`/domainImage/${e?.marketplace_flag}.png`}
                        style={{ height: "20px" }}
                        loading="lazy"
                        className="me-3"
                        onError={(e) => {
                          e.target.src = "/media/domainImage/red-flag.png";
                        }}
                      />
                      {e?.region_label === "Ecommerce"
                        ? "Ecommerce"
                        : `Amazon.${e?.account_type || ""}.${
                            e?.short_name || ""
                          }`}
                    </>
                  ),
                  value: e.marketplace_id,
                  region: e.region,
                  marketplace_id: e?.marketplace_id,
                  marketplace: e?.marketplace,
                  sales_channel: e?.sales_channel,
                },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: (
                <>
                  <img
                    src={`/domainImage/${e?.marketplace_flag}.png`}
                    style={{ height: "20px" }}
                    loading="lazy"
                    className="me-3"
                    onError={(e) => {
                      e.target.src = "/media/domainImage/red-flag.png";
                    }}
                  />
                  {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                </>
              ),
              value: e.marketplace_id,
              marketplace_id: e?.marketplace_id,
              region: e.region,
              marketplace: e?.marketplace,
              sales_channel: e?.sales_channel,
            });
          }
          return acc;
        }, []);

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);

        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);

      setMarketplaceList([]);
      message.destroy();
      message.warning(SalesChannelListResponse?.message);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);
  useEffect(() => {
    if (GetCategoryListResponse?.status === true) {
      setCategoryLoading(false);
      setCategoryList(
        GetCategoryListResponse?.data?.data?.map((d) => ({
          label: d?.category,
          value: d?.category,
        }))
      );
      setSign(GetCategoryListResponse?.data?.currency);
      fakeActionAnalytics("GetCategoryListResponse");
    } else if (GetCategoryListResponse?.status === false) {
      setCategoryList([]);
      setCategoryLoading(false);
      message.destroy();
      message.warning(GetCategoryListResponse?.message);
      fakeActionAnalytics("GetCategoryListResponse");
    }
  }, [GetCategoryListResponse]);
  useEffect(() => {
    if (GetSubCategoryListResponse?.status === true) {
      setSubCategoryLoading(false);
      setSubCategoryList(
        GetSubCategoryListResponse?.data?.data?.map((d) => ({
          label: d?.sub_category,
          value: d?.sub_category,
        }))
      );
      setSign(GetSubCategoryListResponse?.data?.currency);
      fakeActionAnalytics("GetSubCategoryListResponse");
    } else if (GetSubCategoryListResponse?.status === false) {
      setSubCategoryList([]);
      setSubCategoryLoading(false);
      message.destroy();
      message.warning(GetSubCategoryListResponse?.message);
      fakeActionAnalytics("GetSubCategoryListResponse");
    }
  }, [GetSubCategoryListResponse]);

  useEffect(() => {
    if (GetVendorAnalyticsByChartResponse?.status === true) {
      setChartData(GetVendorAnalyticsByChartResponse?.data?.data || {});
      setChartLoading(false);
      setSign(GetVendorAnalyticsByChartResponse?.data?.currency);
      fakeActionAnalytics("GetVendorAnalyticsByChartResponse");
    } else if (GetVendorAnalyticsByChartResponse?.status === false) {
      setChartData({});
      setChartLoading(false);
      fakeActionAnalytics("GetVendorAnalyticsByChartResponse");
    }
  }, [GetVendorAnalyticsByChartResponse]);
  useEffect(() => {
    if (GetVendorAnalyticsByCardResponse?.status === true) {
      setSign(GetVendorAnalyticsByCardResponse?.data?.currency);
      setCardRecords(GetVendorAnalyticsByCardResponse?.data?.data || {});
      setCardLoading(false);
      fakeActionAnalytics("GetVendorAnalyticsByCardResponse");
    } else if (GetVendorAnalyticsByCardResponse?.status === false) {
      setSign("");
      setCardRecords({});
      setCardLoading(false);
      fakeActionAnalytics("GetVendorAnalyticsByCardResponse");
    }
  }, [GetVendorAnalyticsByCardResponse]);

  const cardData = {
    sell_out: {
      sign: sign,
    },

    net_cost_amount: {
      sign: sign,
    },
    ordered_quantity_unit_size: {},
    total_orders: {},
  };

  const colorUpdate = (value) => {
    if (parseFloat(value) >= 0) {
      return <span style={{ color: "green" }}>{value}%</span>;
    }
    return <span style={{ color: "red" }}>{value}%</span>;
  };

  useEffect(() => {
    if (chartLoading) return;
    if (Object?.keys(chartData)?.length > 0) {
      const chartOptions = {
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: chartData?.lable,
        },
        yAxis: [
          {
            title: {
              text: "Sell-In",
            },
            labels: {
              formatter: function () {
                return SwapCommaAndDot(
                  parseFloat(this.value || 0)?.toLocaleString(),
                  sign || "",
                  "",
                  marketplaceSelected?.marketplace_id
                );
              },
            },
          },
          {
            title: {
              text: "Sell-Out",
            },
            labels: {
              formatter: function () {
                return SwapCommaAndDot(
                  parseFloat(this.value || 0)?.toLocaleString(),
                  sign || "",
                  "",
                  marketplaceSelected?.marketplace_id
                );
              },
            },
            opposite: true,
          },
        ],
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Sell-in",
            data: chartData?.sellIn?.map((d) =>
              parseFloat(parseFloat(d).toFixed(2))
            ),
            color: "#efb562",
            yAxis: 0,
          },
          {
            name: "Sell-Out",
            data: chartData?.sellOut?.map((d) =>
              parseFloat(parseFloat(d).toFixed(2))
            ),
            color: "#5da283",
            yAxis: 1,
            dataLabels: {
              enabled: true,
              // format: "{point.y}%", // Display the y-value as the data label for ACOS
              style: {
                textOutline: "none",
                fontWeight: "normal",
                color: "#5da283",
              },
            },
          },
        ],
        tooltip: {
          formatter: function () {
            return `<b>${this.series.name}</b>: ${SwapCommaAndDot(
              parseFloat(this.y || 0)?.toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )}`;
          },
        },
      };
      Highcharts?.chart("line-chart-container", chartOptions);
    }
  }, [chartData, chartLoading]);

  useEffect(() => {
    SalesChannelListAction();

    setFilters({
      ...filters,
      start_date: contextValue?.data?.date_range?.start_date,
      end_date: contextValue?.data?.date_range?.end_date,
    });

    return () => {};
  }, []);

  const currentMonth = moment().month(); // Get the current month (0-indexed)
  const quarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3), "months");
  const quarterEnd = quarterStart.clone().add(3, "months").subtract(1, "day");

  const lastQuarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3) - 3, "months");
  const lastQuarterEnd = lastQuarterStart
    .clone()
    .add(3, "months")
    .subtract(1, "day");
  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
      type: "last_30_days_by_day",
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
      type: "last_30_days_by_day",
    },
    {
      label: "This Quarter",
      value: [dayjs(quarterStart), dayjs(quarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Quarter",
      value: [dayjs(lastQuarterStart), dayjs(lastQuarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
      type: "last_12_months_by_month",
    },
    {
      label: "Custom Range",
      value: [0, 0],
      type: "last_12_months_by_month",
    },
  ];

  const cardTitle = {
    sell_out: "Overall Revenue",

    net_cost_amount: "Sell-In",
    ordered_quantity_unit_size: "Units Shipped",
    total_orders: "Total PO Order",
  };
  return (
    <Wrapper className="p-4 fadeInBottom">
      <div className="d-flex justify-content-end flex-wrap mb-5 fix-over">
        <Select
          placeholder="Select Category"
          style={{ width: "200px" }}
          className="me-3 d-none"
          size="large"
          options={categoryList}
          loading={categoryLoading}
          value={filters?.category || null}
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={(e) => {
            setFilters({
              ...filters,
              category: e,
            });
            setCardLoading(true);
            GetVendorAnalyticByCardAction({
              ...marketplaceSelected,
              start_date: filters?.start_date,
              category: e,
              end_date: filters?.end_date,
            });
            setChartLoading(true);
            GetVendorAnalyticByChartAction({
              ...marketplaceSelected,
              category: e,
              start_date: filters?.start_date,
              end_date: filters?.end_date,
            });
          }}
        />
        <Select
          placeholder="Select Sub Category"
          style={{ width: "200px" }}
          className="me-3 d-none"
          size="large"
          options={subCategoryList}
          loading={subCategoryLoading}
          value={filters?.subCategory || null}
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={(e) => {
            setFilters({
              ...filters,
              sub_category: e,
            });
            setCardLoading(true);
            GetVendorAnalyticByCardAction({
              ...marketplaceSelected,
              start_date: filters?.start_date,
              sub_category: e,
              end_date: filters?.end_date,
            });
            setChartLoading(true);
            GetVendorAnalyticByChartAction({
              ...marketplaceSelected,
              sub_category: e,
              start_date: filters?.start_date,
              end_date: filters?.end_date,
            });
          }}
        />

        <DatePicker.RangePicker
          style={{ width: "270px" }}
          presets={rangePresets}
          size="large"
          allowClear={false}
          className="me-3"
          getPopupContainer={(trigger) => trigger.parentNode}
          value={
            filters?.start_date && filters?.end_date
              ? [
                  dayjs(filters?.start_date, "YYYY-MM-DD"),
                  dayjs(filters?.end_date, "YYYY-MM-DD"),
                ]
              : null
          }
          onChange={(e) => {
            setFilters({
              ...filters,
              start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
              end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
            });

            const date_range = {
              start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
              end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
            };
            contextValue?.updateCommonGlobalVal({
              date_range,
            });
            updateDate(date_range);

            setCardLoading(true);
            GetVendorAnalyticByCardAction({
              region: marketplaceSelected?.region,
              sales_channel: marketplaceSelected?.sales_channel,
              ...filters,
              start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
              end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
            });
            setChartLoading(true);
            GetVendorAnalyticByChartAction({
              region: marketplaceSelected?.region,
              ...filters,
              sales_channel: marketplaceSelected?.sales_channel,
              start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
              end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
            });
          }}
        />
        <Select
          placeholder="Select Marketplace"
          style={{ width: "250px" }}
          size="large"
          getPopupContainer={(trigger) => trigger.parentNode}
          options={marketplaceList}
          onChange={(_, e) => {
            setMarketplaceSelected({
              marketplace: e?.marketplace,
              marketplace_id: e?.marketplace_id,
              region: e?.region,
              sales_channel: e?.sales_channel,
            });
            setCardLoading(true);
            GetVendorAnalyticByCardAction({
              region: e?.region,
              sales_channel: e?.sales_channel,
              ...filters,
            });
            setChartLoading(true);
            GetVendorAnalyticByChartAction({
              region: e?.region,
              sales_channel: e?.sales_channel,
              ...filters,
            });
          }}
          value={marketplaceSelected?.marketplace_id}
          loading={marketplaceLoading}
        />
      </div>
      <div
        className="my-5 row gap-5"
        style={{ padding: "10px", justifyContent: "center" }}
        // style={{
        //   display: "grid",
        //   gridTemplateColumns: "repeat(auto-fill, minmax(361px, 1fr))",
        //   gap: "10px",
        //   filter: cardLoading ? "blur(5px)" : "blur(0px)",
        // }}
      >
        {Object?.entries(cardData)?.map(([key, value], i) => {
          return (
            <div
              key={i}
              className="col"
              style={{
                background: "#FFF",
                padding: "20px",
                borderRadius: "10px",
                minWidth: "287px",
                maxWidth: "400px",
              }}
            >
              <div className="d-grid">
                <div className="d-grid">
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "larger",
                      color: "#6D3057",
                      textTransform: "capitalize",
                    }}
                  >
                    {cardTitle?.[key]}
                  </span>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "x-large",
                      color: "#000",
                    }}
                  >
                    {SwapCommaAndDot(
                      parseFloat(
                        cardRecords?.[key]?.value || 0
                      )?.toLocaleString(),
                      value?.sign || "",
                      "",
                      marketplaceSelected?.marketplace_id
                    )}
                  </span>
                </div>

                <div
                  style={{
                    backgroundColor: "#F4E7E5",
                    padding: "9px 6px",
                    borderRadius: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div className="d-grid me-3">
                    <span
                      style={{
                        color: "#2C7E10",
                        fontSize: "initial",
                        fontWeight: "500",
                      }}
                    >
                      {colorUpdate(
                        parseFloat(
                          cardRecords?.[key]?.previous_year_percentage || 0
                        )?.toLocaleString()
                      )}
                    </span>
                    <span style={{ fontWeight: "100" }}>Previous year</span>
                  </div>
                  <div
                    style={{
                      background: "#B8B1B0",
                      width: 1,
                      height: 44,
                    }}
                  />

                  <div className="d-grid ms-3">
                    <span
                      style={{
                        color: "#2C7E10",
                        fontSize: "initial",
                        fontWeight: "500",
                      }}
                    >
                      {colorUpdate(
                        parseFloat(
                          cardRecords?.[key]?.previous_period_percentage || 0
                        )?.toLocaleString()
                      )}
                    </span>
                    <span style={{ fontWeight: "100" }}>Previous Period</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="card card-xl-stretch mb-xl-8">
        <div className="card-header border-0">
          <div className="card-title fw-bold text-gray-900">
            Sell-in / Sell-Out
          </div>
        </div>
        {chartLoading && (
          <div className="card-body pt-0">
            <div className="d-flex align-items-center justify-content-center h-400px">
              <Spin />
            </div>
          </div>
        )}
        <div
          className="card-body pt-0"
          style={{ display: chartLoading ? "none" : "block" }}
        >
          <div id="line-chart-container" />
        </div>
      </div>
    </Wrapper>
  );
};

export default VendorOverview;
