import { EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Popover,
  Result,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../commonContext";
import { ENDPOINT, checkPermission } from "../../../config";
import Create from "./lib/create";
import { Wrapper } from "./style";

const userMenus = [
  "analytics",
  "account-status",
  "vendor-order",
  "reports",
  "inventory",
  "brand-analytics",
  "amazon-catalog-health",
  "catalog",
  "finance",
  "customer-sentiment",
  "vendor",
  "campaign-management",
  "brand-protection",
  "advertising-analytics",
  "agency",
  "client-data",
  "setting",
  "application-logs",
];
const ManageUser = (props) => {
  const {
    GetUserListAction,
    SwitchUserAction,
    UserDeleteAction,
    fakeActionManageUser,
  } = props;
  const history = useHistory();
  const [statusSelected, setStatusSelected] = useState("Active");
  const UserListResponse = useSelector(
    (state) => state.User.UserListResponse || {}
  );
  const SwitchUserResponse = useSelector(
    (state) => state.User.SwitchUserResponse || {}
  );
  const UserDeleteResponse = useSelector(
    (state) => state.User.UserDeleteResponse || {}
  );
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState({});

  const [searchText, setSearchText] = useState("");
  const [createView, setCreateView] = useState(false);

  const [in_active_users, setIn_active_users] = useState([]);

  useEffect(() => {
    if (UserListResponse?.status === true) {
      setIn_active_users(UserListResponse?.data?.in_active_users || []);
      setUserList(UserListResponse?.data?.records || []);
      setUserLoading(false);
      fakeActionManageUser("UserListResponse");
    } else if (UserListResponse?.status === false) {
      setUserLoading(false);
      fakeActionManageUser("UserListResponse");
    }
  }, [UserListResponse]);
  const contextValue = useContext(GlobalContext);

  useEffect(() => {
    if (SwitchUserResponse?.status === true) {
      localStorage.setItem(
        "admin",
        JSON.stringify({
          auth_token: localStorage.getItem("token"),
          user_data: JSON.parse(localStorage.getItem("user")),
        })
      );
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...SwitchUserResponse?.data?.user_data,
          credentials: SwitchUserResponse?.data?.credentials,
        })
      );
      localStorage.setItem("token", SwitchUserResponse?.data?.auth_token);
      localStorage.setItem(
        "userType",
        SwitchUserResponse?.data?.user_data?.u_type
      );
      localStorage.setItem("menus", JSON.stringify(userMenus));
      localStorage.setItem(
        "account_type",
        SwitchUserResponse?.data?.account_type
      );
      message.destroy();
      message.success(SwitchUserResponse?.message);

      contextValue?.updateCommonGlobalVal({
        user_: {
          ...SwitchUserResponse?.data?.user_data,
          credentials: SwitchUserResponse?.data?.credentials,
        },
        admin: true,
        account_type: SwitchUserResponse?.data?.account_type || 1,
      });

      history.push("/analytics/overview");

      fakeActionManageUser("SwitchUserResponse");
    } else if (SwitchUserResponse?.status === false) {
      message.destroy();
      message.error(SwitchUserResponse?.message);
      fakeActionManageUser("SwitchUserResponse");
    }
  }, [SwitchUserResponse]);

  useEffect(() => {
    if (UserDeleteResponse?.status === true) {
      message.destroy();
      message.success(UserDeleteResponse?.message);
      setUserLoading(true);
      GetUserListAction(searchText);
      fakeActionManageUser("UserDeleteResponse");
    } else if (UserDeleteResponse?.status === false) {
      message.destroy();
      message.error(UserDeleteResponse?.message);
      fakeActionManageUser("UserDeleteResponse");
    }
  }, [UserDeleteResponse]);

  useEffect(() => {
    setUserLoading(true);
    GetUserListAction();
    return () => {};
  }, []);

  const SwitchUser = (value) => {
    message.destroy();
    if (!value?.u_id) return message.warning("User Id not found");
    if (parseInt(value?.u_type) === 1)
      return message.warning("You cannot switch yourself!");
    message.loading("Loading...", 0);
    SwitchUserAction(value?.u_id);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Name",

      sorter: (a, b) =>
        a.u_name.toLowerCase().localeCompare(b.u_name.toLowerCase()),
      render: (d) => {
        return (
          <div className="d-flex">
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
              <a>
                {d?.u_photo !== null ? (
                  <img
                    loading="lazy"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                    className="bisness-logo"
                    src={ENDPOINT + "images/user-images/" + d?.u_photo}
                  />
                ) : (
                  <div className="symbol-label fs-3 bg-light-danger text-danger">
                    {d.u_name.charAt(0)}
                  </div>
                )}
              </a>
            </div>
            <div className="d-flex flex-column">
              <a className="text-gray-800 text-hover-primary mb-1 fw-bolder">
                {d?.u_name || "-"}
              </a>
              <span>{d?.u_email || "-"}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "u_email",
      key: "u_email",
    },
    {
      title: "Mobile",
      dataIndex: "u_contact_no",
      key: "u_contact_no",
    },
    {
      title: "Channel",
      dataIndex: "marketplace",
      key: "marketplace",
      align: "center",
      render: (e) => {
        if (e?.length === 0) {
          return <span>-</span>;
        }

        const Seller = e?.filter((d) => d?.account_type === "Seller");
        const Vendor = e?.filter((d) => d?.account_type === "Vendor");
        return (
          <Popover
            getPopupContainer={(target) => target.parentNode}
            content={
              <div
                style={{
                  display: "grid",
                  justifyItems: "center",
                }}
              >
                {Seller?.map((d, i) => (
                  <span key={i}>
                    Amazon.{d?.account_type}.{d?.short_name}
                  </span>
                ))}
                {Vendor?.length !== 0 && Seller?.length !== 0 && (
                  <div
                    style={{
                      background: "#c4c4c4",
                      height: "1px",
                      width: "100%",
                    }}
                    className="my-2"
                  />
                )}
                {Vendor?.map((d, i) => (
                  <span key={i}>
                    Amazon.{d?.account_type}.{d?.short_name}
                  </span>
                ))}
              </div>
            }
            placement="left"
            title="Marketplace Details"
            trigger="click"
          >
            <EyeOutlined style={{ cursor: "pointer" }} />
          </Popover>
        );
      },
    },
    {
      title: "Pending Reports",
      sorter: (a, b) => a.pending_reports - b.pending_reports,

      render: (e) => {
        return <span>{e?.pending_reports || "0"}</span>;
      },
    },
    {
      title: "Status",
      // sorter: (a, b) => a.pending_reports - b.pending_reports,

      render: (e) => {
        console.log(e?.u_id === 34 || e?.u_id === 54, "e");
        if (e?.u_id === 34 || e?.u_id === 54) {
          return (
            <Tag bordered={false} style={{ fontSize: "14px" }} color="green">
              Reviewed
            </Tag>
          );
        }
        return (
          <Tag style={{ fontSize: "14px" }} bordered={false} color="blue">
            Under Review
          </Tag>
        );
      },
    },
    {
      title: "Switch User",

      render: (value) => {
        if (checkPermission(contextValue, "manage_user", "switch")) {
          return <>-</>;
        }
        if (parseInt(value?.u_type) === 1) {
          return <>-</>;
        }
        return (
          <>
            <a
              onClick={() => {
                SwitchUser(value);
              }}
              className="d-flex align-items-center fw-bolder"
            >
              <span className="svg-icon svg-icon-info svg-icon-2x me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.3"
                    width={12}
                    height={2}
                    rx={1}
                    transform="matrix(-1 0 0 1 15.5 11)"
                    fill="black"
                  />
                  <path
                    d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z"
                    fill="black"
                  />
                  <path
                    d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                    fill="#C4C4C4"
                  />
                </svg>
              </span>
              Switch User
            </a>
          </>
        );
      },
    },
    {
      title: "Action",

      render: (text, record) => (
        <Space size="middle">
          <div className="d-flex flex-shrink-0">
            <button
              disabled={checkPermission(contextValue, "manage_user", "edit")}
              onClick={() => {
                setSelectedRow(text);
                setCreateView(true);
              }}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            >
              <span className="svg-icon svg-icon-3">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
            {parseInt(text?.u_type) !== 1 && (
              <Popconfirm
                title="Are you sure to delete this user?"
                placement="left"
                cancelText="No"
                disabled={checkPermission(
                  contextValue,
                  "manage_user",
                  "delete"
                )}
                okText="Yes"
                onConfirm={() => {
                  if (parseInt(text?.u_type) === 1) {
                    return;
                  }
                  if (text?.u_id) {
                    message.loading("Deleting...", 0);
                    UserDeleteAction(text?.u_id);
                  }
                }}
              >
                <button
                  disabled={checkPermission(
                    contextValue,
                    "manage_user",
                    "delete"
                  )}
                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                >
                  <span className="svg-icon svg-icon-3">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.5"
                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.5"
                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </button>
              </Popconfirm>
            )}
          </div>
        </Space>
      ),
    },
  ];

  if (checkPermission(contextValue, "manage_user", "view")) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    );
  }
  return (
    <Wrapper className="fadeInBottom">
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder="Search..."
              onPressEnter={() => {
                setUserLoading(true);
                GetUserListAction(searchText);
              }}
              size="large"
              className="w-200px"
            />
            <Select
              placeholder="Select Status"
              className="ms-3 w-125px"
              onChange={(e) => {
                setUserLoading(true);
                setTimeout(() => {
                  setUserLoading(false);
                }, 300);
                setStatusSelected(e);
              }}
              size="large"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              value={statusSelected}
              options={[
                { label: "All", value: "all" },
                { label: "Active", value: "Active" },
                { label: "In Active", value: "InActive" },
              ]}
            />
          </div>
          <div className="card-toolbar">
            <Button
              disabled={
                checkPermission(contextValue, "manage_user", "add") ||
                contextValue?.data?.user_?.u_type === 3
              }
              onClick={() => setCreateView(true)}
              type="primary"
            >
              + Add
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Table
            loading={userLoading}
            dataSource={userList
              ?.filter((d) =>
                statusSelected === "all"
                  ? d
                  : statusSelected === "Active"
                  ? !in_active_users?.includes(d?.u_id)
                  : in_active_users?.includes(d?.u_id)
              )
              ?.map((d, i) => ({ ...d, index: i + 1 }))}
            columns={columns}
            pagination={{
              showTotal: (total) => `Total ${total} users`,
              showSizeChanger: true,
            }}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
      {createView && (
        <Create
          isOpen={createView}
          selectedRow={selectedRow}
          getList={() => {
            setUserLoading(true);
            GetUserListAction(searchText);
            setSelectedRow({});
          }}
          onClose={() => {
            setCreateView(false);
            setSelectedRow({});
          }}
          {...props}
        />
      )}
    </Wrapper>
  );
};

export default ManageUser;
